import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Ratings from 'react-ratings-declarative';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import StarIcon from '@material-ui/icons/StarRateOutlined';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import api from "../../apiService";
// import {push} from 'react-router-redux';
// import store from '../../../../Store';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 1,
    marginRight: theme.spacing.unit * 1,
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginBottom: theme.spacing.unit * 3,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '5rem',
    paddingTop: theme.spacing.unit * 8,
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '2rem',
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  rating: {
    alignSelf: 'center',
  },
  ratingText: {
    textAlign: 'center',
  }
});


class ChemistRating extends Component {
  state = {
    rating: 3,
    ratingText: 'Average',
    comment: '',
    showSuccess: false,
    showFailure: false,
    loading: true,
    chemistName: 'your chemist',
  };

  changeRating = (newRating) => {
    let ratingText = ''
    if (newRating === 1) {
      ratingText = 'Poor';
    } else if (newRating === 2) {
      ratingText = 'Fair';
    } else if (newRating === 3) {
      ratingText = 'Average';
    } else if (newRating === 4) {
      ratingText = 'Good';
    } else if (newRating === 5) {
      ratingText = 'Excellent';
    }
    this.setState({ rating: newRating, ratingText });
  }

  componentDidMount() {
    const { chemistID } = this.props.match.params;
    api.get(`/api/v1/locator/${chemistID}`)
        .then((response) => {
          this.setState({chemistName: response.data.name, loading: false});
        })
        .catch((error) => {
          console.log(error);
          this.setState({showFailure: true, loading: false});
        });
  }

  handleSubmit(event) {
    const { chemistID } = this.props.match.params;
    event.preventDefault();
    this.setState({loading: true});
    api.post('/api/v1/rating/', { pharmacy: chemistID, rating: this.state.rating })
      .then((response) => {
        this.setState({showSuccess: true, loading: false});
      })
      .catch((error) => {
        console.log(error);
        this.setState({showFailure: true, loading: false});
      });
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>Loading...</Typography>
        </div>
      );
    }

    if (this.state.showSuccess) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>Success</Typography>
          <Typography className={classes.subHeading} color={'textSecondary'}>Your rating has been submitted.</Typography>
        </div>
      );
    }

    if (this.state.showFailure) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>There has been an error submitting your rating.</Typography>
          <Typography className={classes.subHeading} color={'textSecondary'}>Please contact us for help.</Typography>
        </div>
      );
    }

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <StarIcon />
          </Avatar>

          <Typography variant="h6">Rate your experience with</Typography>
          <Typography variant="h5">{this.state.chemistName}</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => this.handleSubmit(event)}
            noValidate
          >
            <FormControl
              className={classes.field}
              required={true}
              fullWidth
            >
              <div className={classes.rating}>
                <Ratings
                  rating={this.state.rating}
                  widgetRatedColors='#ffb300'
                  widgetHoverColors='#ffb300'
                  changeRating={this.changeRating}
                  widgetDimensions={'35px'}
                >
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                  <Ratings.Widget />
                </Ratings>
              </div>
            <Typography className={classes.ratingText} color={'textSecondary'}>{this.state.ratingText}</Typography>
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.loading}
            >
              { this.state.loading ? 'Submitting...' : 'Submit' }
            </Button>

          </form>
        </Paper>
      </main>
    );
  }
}

ChemistRating.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChemistRating);
