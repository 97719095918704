import { connect } from 'react-redux';
import PrimaryCategoryComponent from './PrimaryCategoryComponent';
import { primaryCategoryOperations } from './duck';

const mapStateToProps = state => {
  const { loading, category } = state.primaryCategory;
  return { loading, category }
};

const PrimaryCategoryContainer = connect(
  mapStateToProps,
  {
    fetchCategory: primaryCategoryOperations.fetchCategory
  }
)(PrimaryCategoryComponent);

export default PrimaryCategoryContainer;
