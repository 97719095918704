import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Helmet } from 'react-helmet';
import {Link} from "react-router-dom";
import Paper from '@material-ui/core/Paper';

import bg from './images/homepage-banner.jpg';
import { tableData } from './AdvertisingData';
import Button from '@material-ui/core/Button';
import advertisingGuide from './files/advertising_guide.pdf';
import ContactForm from "./ContactForm";
import overlayImage from "./images/advertise-category-page.png";


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    marginTop: theme.spacing.unit * 4,
  },
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: '30% 65%',
    minHeight: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '1.8rem',
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '1.8rem',
    marginBottom: theme.spacing.unit * 4,
  },
  contentContainer: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 50,
    paddingBottom: 50,
    textAlign: 'center',
  },
  mainContent: {
    fontSize: '1.25rem',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey['200'],
    },
  },
  tableHeader: {
    fontSize: '1.25rem',
  },
  button: {
    borderRadius: 40,
    padding: '6px 16px',
    margin: theme.spacing.unit,
    boxShadow: 'none',
  },
  phoneImage: {
    [theme.breakpoints.up('md')]: {
      right: '29%',
      position: 'absolute',
    },
  },
});


class AdvertisingComponent extends Component {

  render() {
    const {classes} = this.props;

    return (
      <div>
        <Helmet>
          <title>Chemist Select - Advertising</title>
          <meta name="description" content="Advertise with Chemist Select."/>
        </Helmet>
        <div>
          <div className={classes.bg}>
            <img src={overlayImage} width={250} className={classes.phoneImage} />
          </div>
        </div>

        <div className={classes.contentContainer}>
          <Grid container justify={"center"}>
            <Grid item md={8} xs={12}>
              <Typography paragraph variant={'h6'} className={classes.heading}>
                Advertising Packages
              </Typography>
              <Typography paragraph color={'textSecondary'} className={classes.mainContent}>
                At Chemist Select we can help you connect your products and messages with our audience.
                Below are our various channels and locations where we can advertise and have tailored common
                package requests together.
              </Typography>
              <Paper className={classes.root}>
              {tableData.map((val, index) => {
                return (
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {val.header.map((val) => (
                          <TableCell align={'center'}><Typography className={classes.tableHeader}>{val}</Typography></TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {val.rows.map((row, rowInd) => (
                        <TableRow className={classes.row} key={rowInd}>
                          {row.map(rowVal => (
                            <TableCell align={'center'}><Typography>{rowVal}</Typography></TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                );
              })}
              </Paper>
              </Grid>
          </Grid>

          <Grid container justify={"center"}>
            <Grid item md={8} xs={12}>
              <div style={{marginTop: 40}}>
                <Typography paragraph variant={'h6'} className={classes.heading}>
                  Find out more about placement specifics
                </Typography>
                <Link to={advertisingGuide} target="_blank" download style={{textDecoration: 'none'}}>
                  <Button variant="contained" className={classes.button} color={'primary'} onClick={() => console.log('download')}>
                    <span style={{padding: 12, paddingLeft: 30, paddingRight: 30, fontSize: '1.25rem', textDecoration: 'none'}}>Download our in depth advertising guide</span>
                  </Button>
                </Link>
                <Link to={'advertising-policy'} target="_blank" style={{textDecoration: 'none'}}>
                  <Typography>Read our advertising policy here</Typography>
                </Link>
              </div>
              <div style={{marginTop: 40}}>
                <Typography paragraph variant={'h6'} className={classes.heading}>
                  Ready to start advertising with us?
                </Typography>
                <ContactForm onSubmit={this.onSubmit} />
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
    );
  }
}

AdvertisingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdvertisingComponent);
