import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '5rem',
    paddingTop: theme.spacing.unit * 8,
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '2rem',
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  }
});

class SignUpConfirmationComponent extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.heading} color={'textSecondary'}>Thanks for joining Chemist Select!</Typography>
        <Typography className={classes.subHeading} color={'textSecondary'}>
          You should receive an email shortly with a link to activate your account.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(SignUpConfirmationComponent);
