import types from './types';

const INITIAL_STATE = {
  signUpLoading: false,
  signUpPasswordError: null,
  signUpEmailError: null,
  signedIn: false,
  user: {},
  signInLoading: false,
  signInError: null
};

const accountReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.SIGN_UP_REQUEST:
      return {
        ...state,
        signUpLoading: true,
        signUpFirstNameError: null,
        signUpLastNameError: null,
        signUpPasswordError: null,
        signUpEmailError: null
      };

    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpLoading: false,
        signUpPasswordError: null,
        signUpEmailError: null
      };

    case types.SIGN_UP_ERROR:
      return {
        ...state,
        signUpLoading: false,
        signUpFirstNameError: action.payload.first_name,
        signUpLastNameError: action.payload.last_name,
        signUpPasswordError: action.payload.password,
        signUpEmailError: action.payload.email,
      };

    case types.SIGN_UP_CLEAR:
      return {
        ...state,
        signUpFirstNameError: null,
        signUpLastNameError: null,
        signUpPasswordError: null,
        signUpLoading: false,
        signUpEmailError: null
      };

    case types.SIGN_IN_REQUEST:
      return {
        ...state,
        signInLoading: true,
        signInError: null
      };

    case types.SIGN_IN_SUCCESS:
      return {
        ...state,
        signedIn: true,
        user: action.payload,
        signInLoading: false,
        signInError: null
      };

    case types.SIGN_IN_ERROR:
      return {
        ...state,
        signInLoading: false,
        signInError: action.payload
      };

    case types.SIGN_IN_CLEAR:
      return { ...state, signInLoading: false, signInError: null };

    case types.SIGN_OUT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default accountReducer;
