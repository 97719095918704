import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import api from '../../../../apiService';
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import CompareIcon from "../../../home/images/comapare-icon-purple.svg";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    paddingTop: theme.spacing.unit * 4,
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '2rem',
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  contentContainer: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingBottom: 50,
    textAlign: 'center',
  },
  featureHeading: {
    fontWeight: '900',
    fontSize: '1.5rem',
  },
  featureContent: {
    fontSize: '1rem',
  },
  compareIcon: {
    marginTop: -25,
    marginBottom: -25,
  },
  bottomText: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  }
});


class ActivateComponent extends Component {
  state = {
    title: 'Your account is activating...',
    subtitle: 'This should only take a second.',
  };

  componentDidMount() {
    const { uid, token } = this.props.match.params;
    api.post('/api/v1/auth/users/activate/', { uid, token })
      .then((response) => {
        if(response.status === 204) {
          this.setState({
            title: 'Your account has been activated!',
            subtitle: 'Login to start saving on all your favourite products.'
          });
          // TODO: redirect to the login page
        } else {
          this.setState({
            title: 'There has been an error activating your account',
            subtitle: 'Please contact us for help.'
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          title: 'There has been an error activating your account',
          subtitle: 'Please contact us for help.'
        });
      });
  }

  render() {
    const { classes } = this.props;
    const { title, subtitle } = this.state;
    return (
      <div className={classes.root}>
        <Grid container justify={"center"}>
          <Grid item md={8} xs={12}>
            <Typography className={classes.heading} color={'textSecondary'}>{title}</Typography>
            <Typography className={classes.subHeading} color={'textSecondary'}>{subtitle}</Typography>
          </Grid>
          <Grid item md={8} xs={12}>
            <div className={classes.contentContainer}>
              <Grid container justify={"center"}>
                <Grid item md={8} xs={12} className={classes.featureContainer}>
                  <Grid container justify={"center"}>
                    <Grid item md={4} xs={12}>
                      <SearchIcon color={"primary"} style={{fontSize: 100}}/>
                      <Typography paragraph color={'primary'} variant={'h6'} className={classes.featureHeading}>
                        Search
                      </Typography>
                      <Typography paragraph color={'textSecondary'} className={classes.featureContent}>
                        Search for your product
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      {/*<ListIcon color={"primary"} style={{fontSize: 100}}/>*/}
                      <div className={classes.compareIcon}>
                        <img src={CompareIcon} width={150} />
                      </div>
                      <Typography paragraph color={'primary'} variant={'h6'} className={classes.featureHeading}>
                        Compare
                      </Typography>
                      <Typography paragraph color={'textSecondary'} className={classes.featureContent}>
                        Compare prices
                      </Typography>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <ShoppingCartIcon color={"primary"} style={{fontSize: 100}}/>
                      <Typography paragraph color={'primary'} variant={'h6'} className={classes.featureHeading}>
                        Select
                      </Typography>
                      <Typography paragraph color={'textSecondary'} className={classes.featureContent}>
                        Select your product
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={8} xs={12} className={classes.bottomText}>
            <Typography paragraph color={'textSecondary'}>
              The Chemist Select Apps and website provide consumers with an easy to use and powerful savings tool to help
              them purchase both prescription and non-prescription products from chemists at cheaper prices.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ActivateComponent);