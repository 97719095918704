import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Typography from '@material-ui/core/Typography';
import ContentLoader from 'react-content-loader';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Helmet } from 'react-helmet';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RoundedButton from "../core/RoundedButton";
import store from "../../Store";
import {push} from "react-router-redux";
import Grid from "@material-ui/core/Grid/Grid";
import PrescriptionImage from './images/prescription-category.jpg';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  bgOverlay: {
    background: fade(theme.palette.primary.dark, 0.75),
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bg: {
    backgroundSize: 'cover',
    backgroundPosition: '20% 50%',
    minHeight: 200,
    position: 'relative',
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    background: fade(theme.palette.common.white, 0.75),
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '2.5rem',
    color: theme.palette.common.white
  },
  subHeading: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: theme.palette.common.white
  },
  card: {
    width: 325,
    height: 310,
    margin: theme.spacing.unit * 2,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      background: fade(theme.palette.common.white, 0.75),
    },
  },
  media: {
    height: 0,
    paddingTop: '70%',
  },
  subCatTitle: {
    textDecoration: 'none',
    fontSize: '1.5rem',
  },
  tableRoot: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  // table: {
  //   minWidth: 500,
  // },
  tableWrapper: {
    overflowX: 'auto',
  },
  dataWrapper: {
    margin: theme.spacing.unit * 2,
  }
});


const ListLoader = props => (
	<ContentLoader
		height={200}
		width={725}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<circle cx="10" cy="20" r="8" />
		<rect x="25" y="15" rx="5" ry="5" width="700" height="10" />
		<circle cx="10" cy="50" r="8" />
		<rect x="25" y="45" rx="5" ry="5" width="700" height="10" />
		<circle cx="10" cy="80" r="8" />
		<rect x="25" y="75" rx="5" ry="5" width="700" height="10" />
		<circle cx="10" cy="110" r="8" />
		<rect x="25" y="105" rx="5" ry="5" width="700" height="10" />
    <circle cx="10" cy="140" r="8" />
		<rect x="25" y="135" rx="5" ry="5" width="700" height="10" />
    <circle cx="10" cy="170" r="8" />
		<rect x="25" y="165" rx="5" ry="5" width="700" height="10" />
	</ContentLoader>
);


class ListComponent extends Component {
  constructor(props) {
    super(props);
    this.props.fetchList();
  }

  renderHead() {
    return (
      <Helmet>
        <title>My List - Chemist Select</title>
        <meta name="description" content='My watch list of products.' />
      </Helmet>
    );
  }

  handleRemoveClick (id) {
    this.props.removeListItem(id);
  }

  renderImage(item) {
    if (item.product.prescription) {
      return (
        <img
          src={PrescriptionImage}
          alt={item.product.primary_provider_product.title}
          width={100}
        />
      );
    }
    return (
      <img
        src={item.product.primary_provider_product.images ? item.product.primary_provider_product.images[0].image.category: null}
        alt={item.product.primary_provider_product.title}
        width={100}
      />
    );
  }

  renderTable() {
    const { list, loading, classes } = this.props;

    if (!loading) {
      return (
        <Paper className={classes.tableRoot}>
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="column">Image</TableCell>
                  <TableCell component="th" scope="column">Product</TableCell>
                  <TableCell component="th" scope="column" numeric>Price</TableCell>
                  <TableCell component="th" scope="column" numeric>Was Price</TableCell>
                  <TableCell component="th" scope="column" numeric>Concession Price</TableCell>
                  <TableCell component="th" scope="column" numeric>Discounted Price</TableCell>
                  <TableCell component="th" scope="column" numeric>Private Price</TableCell>
                  <TableCell component="th" scope="column" numeric>Safety Net Price</TableCell>
                  <TableCell component="th" scope="column">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        {this.renderImage(item)}
                      </TableCell>
                      <TableCell>{item.product.primary_provider_product.title} ({item.product.primary_provider_product.provider.title})</TableCell>
                      <TableCell numeric>
                        {item.product.primary_provider_product.current_price.price ? `$${item.product.primary_provider_product.current_price.price}` : 'N/A'}
                      </TableCell>
                      <TableCell numeric>
                        {item.product.primary_provider_product.current_price.rrp ? `$${item.product.primary_provider_product.current_price.rrp}` : 'N/A'}
                      </TableCell>
                      <TableCell numeric>
                        {item.product.primary_provider_product.current_price.concession_price ? `$${item.product.primary_provider_product.current_price.concession_price}` : 'N/A'}
                      </TableCell>
                      <TableCell numeric>
                        {item.product.primary_provider_product.current_price.discounted_price ? `$${item.product.primary_provider_product.current_price.discounted_price}` : 'N/A'}
                      </TableCell>
                      <TableCell numeric>
                        {item.product.primary_provider_product.current_price.private_price ? `$${item.product.primary_provider_product.current_price.private_price}` : 'N/A'}
                      </TableCell>
                      <TableCell numeric>
                        {item.product.primary_provider_product.current_price.safety_net_price ? `$${item.product.primary_provider_product.current_price.safety_net_price}` : 'N/A'}
                      </TableCell>
                      <TableCell>
                        <RoundedButton
                          size={'small'}
                          variant='contained'
                          color={'primary'}
                          onClick={() => store.dispatch(push(`/product/${item.product.slug}`))}
                        >
                          View
                        </RoundedButton>
                        <RoundedButton
                          size={'small'}
                          variant='contained'
                          color={'secondary'}
                          onClick={() => this.handleRemoveClick(item.id)}
                        >
                          Remove
                        </RoundedButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </Paper>
      );
    }
    return (
      <div>
        <ListLoader />
      </div>
    );
  }

  loadMoreProducts() {
    const { next } = this.props;
    if (next) {
      this.props.fetchNextListPage(next);
    }
  }

  renderLoadMore() {
    const { loadingMore, next } = this.props;
    if (next) {
      return (
        <Grid container justify={'center'}>
          <Grid item>
            <RoundedButton
              variant="contained"
              color={'primary'}
              disabled={loadingMore}
              onClick={this.loadMoreProducts.bind(this)}
            >
              Load More
            </RoundedButton>
          </Grid>
        </Grid>
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        {this.renderHead()}
        <div>
          <div className={classes.bg}>
            <div className={classes.bgOverlay} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
              <Typography variant="h1" className={classes.heading}>
                My List
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.dataWrapper}>
          {this.renderTable()}
        </div>

        {this.renderLoadMore()}

      </div>
    );
  }
}

ListComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListComponent);
