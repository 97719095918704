import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

// https://material.io/color/#!/?view.left=0&view.right=1&primary.color=665cac&secondary.color=FED931
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#c4a4ff',
      main: '#9175db',
      dark: '#6049a9',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffa341',
      main: '#ff7200',
      dark: '#c44200',
      contrastText: '#fff',
    },
    typography: {
      useNextVariants: true,
    },
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
