import types from './types.js';

const toggleSideMenu = (open) => {
  return({
    type: types.TOGGLE_SIDE_MENU,
    payload: open
  });
};

const requestProductAutocomplete = () => {
  return ({
    type: types.REQUEST_PRODUCT_AUTOCOMPLETE,
  });
};

const receiveProductAutocomplete = (products) => {
  return ({
    type: types.RECEIVE_PRODUCT_AUTOCOMPLETE,
    payload: products
  });
};

export default {
  toggleSideMenu,
  requestProductAutocomplete,
  receiveProductAutocomplete,
}
