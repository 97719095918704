import { connect } from 'react-redux';
import SignUpComponent from './SignUpComponent';
import { accountOperations } from '../../duck';

const mapStateToProps = state => {
  const { signUpLoading, signUpPasswordError, signUpEmailError, signUpFirstNameError, signUpLastNameError } = state.account;
  return { signUpLoading, signUpPasswordError, signUpEmailError, signUpFirstNameError, signUpLastNameError };
};

const SignUpContainer = connect(
  mapStateToProps,
  {
    signUp: accountOperations.signUp,
    signUpClear: accountOperations.signUpClearAction,
  }
)(SignUpComponent);

export default SignUpContainer;
