import types from './types';

const INITIAL_STATE = {
  sideMenuOpen: false,
  autocompleteLoading: false,
  autocompleteProducts: [],
};

const coreReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.TOGGLE_SIDE_MENU:
      return {
        ...state,
        sideMenuOpen: action.payload
      };

    case types.REQUEST_PRODUCT_AUTOCOMPLETE:
      return {
        ...state,
        autocompleteLoading: true,
        autocompleteProducts: []
      };

    case types.RECEIVE_PRODUCT_AUTOCOMPLETE:
      return {
        ...state,
        autocompleteLoading: false,
        autocompleteProducts: action.payload
      };

    default:
      return state;
  }
};

export default coreReducer;
