export const tileData = [
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
  {
    img: 'http://via.placeholder.com/350x150',
    title: 'Image',
    author: 'author',
  },
];

export const reviewData = [
  {
    'title': 'Simple, simple, simple',
    'content': 'Chemist Select enables any person shopping at a chemist in Australia to compare the prices of a prescription or non prescription product.  Chemist select then gives the chemist with the best price. The consumer can then have the item delivered or pick it up at the store. The best part is you can build a chemist shopping list and just press compare every time you want to shop. You can add or delete items before every comparison. Simple, simple, simple.',
    'author': 'Liza Carrington'
  },
  {
    'title': 'Made by Australians for Australian consumers',
    'content': '"Chemist select made by Australians for Australian consumers"',
    'author': 'Nate Smith'
  },
  {
    'title': 'Save, save, save',
    'content': 'Save, save save.  Chemist Select has saved me so much money.  I always thought certain chemists were the cheapest, how wrong was I!  Chemist select is the best Australian company in last 20 years. Thank you',
    'author': 'Janet Cross'
  },
  {
    'title': 'Why did it take so long for someone to think of Chemist Select',
    'content': 'I only shop at a chemist whenever I need a product or desperate for when I have run out of something.  It took me 15 minutes to build my list and now I just run a comparison for the products I need each week, then they are delivered.  Why did it take so long for someone to come up with this??',
    'author': 'Ivan Locke'
  },
  {
    'title': 'Miracle of Chemist Select',
    'content': 'I love using anything that saves me money, but to be able to scan a prescription walk straight into the chemist knowing you are getting the best price and straight out, is a miracle.',
    'author': 'Casper Leopold'
  },
  {
    'title': 'Is there any other way of shopping at chemists?',
    'content': 'I cannot believe how the prices of chemist product vary.  I have been using the Chemist Select App for the last few months and I couldn\'t imagine another way of shopping.  When are you guys doing this for supermarkets, I love it!',
    'author': 'Kay West'
  },
  {
    'title': 'I now know how to get the cheapest chemist prices',
    'content': 'I always thought Chemist Warehouse was the cheapest.  I was in a routine and didn\'t think I could save so much.  I run comparisons on individual items all the time, in store at home and sometimes just for fun.  Chemist Select is the only place that can guarantee the cheapest prices.',
    'author': 'Tray Calvin'
  },
  {
    'title': 'Chemist shopping done with ease',
    'content': 'Chemist Select allowed me to get my chemist shopping done with ease, I didn\'t even have to get the kids out of the car.  As I waited to pick them up from school I compared my previous shopping list and had it delivered the next day.',
    'author': 'Isabel Roth'
  },
];

export const categoryList = [
  {id: 1, title: 'Baby & Child', linkURI: '/category/baby-child'},
  {id: 2, title: 'Beauty', linkURI: '/category/beauty'},
  {id: 3, title: 'Health', linkURI: '/category/health'},
  {id: 4, title: 'Household', linkURI: '/category/household'},
  {id: 5, title: 'Medicines', linkURI: '/category/medicines'},
  {id: 6, title: 'Personal Care', linkURI: '/category/personal-care'},
  {id: 7, title: 'Prescriptions', linkURI: '/category/prescriptions'},
  {id: 8, title: 'Veterinary', linkURI: '/category/veterinary'},
  {id: 9, title: 'Vitamins & Supplements', linkURI: '/category/vitamins-supplements'},
];
