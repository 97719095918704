import Creators from './actions';

import api from '../../../apiService';

const requestProductsAction = Creators.requestProducts;
const receiveProductsAction = Creators.receiveProducts;
const requestCategoryAction = Creators.requestCategory;
const receiveCategoryAction = Creators.receiveCategory;

const fetchProducts = (categorySlug, searchTerm, pageNumber, sort, url) => {
  return (dispatch) => {

    dispatch(requestProductsAction());
    if (url) {
      api.get(url)
        .then(response => {
          dispatch(receiveProductsAction(response.data))
        });
    }
    else {
      let params = {};

      if (categorySlug !== null) {
        params.category__slug = categorySlug;
      }

      if (searchTerm !== null) {
        params.search = searchTerm;
      }

      if (pageNumber !== null) {
        params.page = pageNumber;
      }

      if (sort !== null) {
        params.ordering = sort;
      }

      api.get('/api/v1/product/', {params: params})
        .then(response => {
          dispatch(receiveProductsAction(response.data))
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};

const fetchCategory = (categorySlug) => {
  return (dispatch) => {
    dispatch(requestCategoryAction());
    api.get(`/api/v1/category/${categorySlug}/`)
      .then(response => {
        dispatch(receiveCategoryAction(response.data))
      });
  }
};

export default { fetchProducts, fetchCategory }
