import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { categoryList } from "../home/HomeData";
import store from "../../Store";
import { push } from "react-router-redux";
import ReactGA from 'react-ga';
import {Link} from "react-router-dom";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  list: {
    width: 250,
  },
  subheading: {
    color: theme.palette.text.primary,
    fontSize: 17,
    fontWeight: 'bold'
  },
});

class SideMenuComponent extends Component {

  onDrawerOpen() {
    console.log('Drawer Open');
    ReactGA.event({
      category: 'Navigation',
      action: 'Drawer toggle',
      label: 'Open',
    });
    this.props.toggleSideMenu(true)
  }

  onDrawerClose() {
    console.log('Drawer Close');
    ReactGA.event({
      category: 'Navigation',
      action: 'Drawer toggle',
      label: 'Close',
    });
    this.props.toggleSideMenu(false)
  }

  renderAccountLinks() {
    const { signedIn } = this.props;

    if (signedIn) {
      return (
        <ListItem button onClick={() => store.dispatch(push('/my-list'))}>
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="My Watch List" />
        </ListItem>
      );
    }
    else {
      return (
        <React.Fragment>
          <ListItem button onClick={() => store.dispatch(push('/account/sign-in'))}>
            <ListItemIcon>
              <AccountCircleIcon/>
            </ListItemIcon>
            <ListItemText primary="Login"/>
          </ListItem>

          <ListItem button onClick={() => store.dispatch(push('/account/sign-up'))}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Up" />
          </ListItem>
        </React.Fragment>
      );
    }
  }

  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        <List>
          <div>

            <ListItem button onClick={() => store.dispatch(push('/'))}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>

            {/*<ListItem button onClick={() => store.dispatch(push('/fast-compare'))}>*/}
              {/*<ListItemIcon>*/}
                {/*<SearchIcon />*/}
              {/*</ListItemIcon>*/}
              {/*<ListItemText primary="Fast Compare" />*/}
            {/*</ListItem>*/}
            <Divider/>

            <ListSubheader className={classes.subheading}>Account</ListSubheader>
            {this.renderAccountLinks()}
            
            <Divider/>

            <ListSubheader className={classes.subheading}>Chemist Select</ListSubheader>
            <ListItem button onClick={() => store.dispatch(push('/about'))}>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/about-lists'))}>
              <ListItemText primary="WatchList" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/about-shopping-lists'))}>
              <ListItemText primary="ShoppingLists" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/'))}>
              <ListItemText primary="MyScripts" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/'))}>
              <ListItemText primary="MyServices" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/faqs'))}>
              <ListItemText primary="FAQs" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/privacy-policy'))}>
              <ListItemText primary="Privacy" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/terms-and-conditions'))}>
              <ListItemText primary="Terms & Conditions" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/contact-us'))}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button onClick={() => store.dispatch(push('/advertising'))}>
              <ListItemText primary="Advertising" />
            </ListItem>

            <Divider/>

            <ListSubheader className={classes.subheading}>Categories</ListSubheader>
            {categoryList.map(cat => (
              <ListItem key={cat.id} button onClick={() => store.dispatch(push(cat.linkURI))}>
                <ListItemText primary={cat.title} />
              </ListItem>
            ))}
          </div>
        </List>
        <Divider />
      </div>
    );

    return (
      <SwipeableDrawer
        open={this.props.sideMenuOpen}
        onClose={() => this.onDrawerClose()}
        onOpen={() => this.onDrawerOpen()}
      >
        <div
          tabIndex={0}
          role="button"
          onClick={() => this.props.toggleSideMenu(false)}
          onKeyDown={() => this.props.toggleSideMenu(false)}
        >
          {sideList}
        </div>
      </SwipeableDrawer>
    );
  }
}

SideMenuComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideMenuComponent);
