import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//import {push} from 'react-router-redux';
//import store from '../../../../Store';


const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginBottom: theme.spacing.unit * 3,
  },
});


class SignInForm extends Component {
  state = {
    email: '',
    emailError: null,
    password: '',
    passwordError: null,
    showPassword: false,
  };

  handleSubmit(event) {
    event.preventDefault();
    this.clearErrors();

    if (this.validate()) {
      this.props.onSubmit(this.state.email, this.state.password);
    }
  }

  clearErrors() {
    this.setState({
      emailError: null,
      passwordError: null,
    });
  }

  validate() {
    let emailError = null;
    let passwordError = null;

    // Validate email
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.email)) {
      emailError = 'Please enter a valid email address';
    }
    // Validate password
    if (!this.state.password) {
      passwordError = 'Please enter a password';
    }

    if (emailError || passwordError) {
      this.setState({
        emailError,
        passwordError,
      });
      return false;
    }
    return true;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, [`${prop}Error`]: null });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  renderPasswordError() {
    if (!!this.state.passwordError) {
      return (
        <FormHelperText id="password-text">{this.state.passwordError}</FormHelperText>
      );
    }
  }

  renderEmailError() {
    if (!!this.state.emailError) {
      return (
        <FormHelperText id="email-text">{this.state.emailError}</FormHelperText>
      );
    }
  }

  handleGoogleSignIn(code) {
    this.props.googleSignIn(code.code);
  }

  render() {
    const { classes, error } = this.props;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h4">Login</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => this.handleSubmit(event)}
            noValidate
          >
            {error ? <FormHelperText className={classes.field} error id="password-text">{error}</FormHelperText>:null}
            <FormControl
              className={classes.field}
              error={!!this.state.emailError}
              required={false}
              fullWidth
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                value={this.state.email}
                onChange={this.handleChange('email')}
                inputProps={{
                  required: true,
                  type: 'email'
                }}
                autoComplete="email"
                autoFocus
              />
              {this.renderEmailError()}
            </FormControl>

            <FormControl
              className={classes.field}
              required={false}
              error={!!this.state.passwordError}
              fullWidth
            >
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {this.renderPasswordError()}
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.props.loading}
            >
              { this.props.loading ? 'Signing In...' : 'Login' }
            </Button>

          </form>

          <Typography>Don't have an account... <Link to={'/account/sign-up'}>Sign Up Here</Link></Typography>
          <Typography><Link to={'/account/password/forgot'}>Forgot password?</Link></Typography>
        </Paper>
      </main>
    );
  }
}

SignInForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignInForm);
