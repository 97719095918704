const REQUEST_PRODUCT = 'REQUEST_PRODUCT';
const RECEIVE_PRODUCT = 'RECEIVE_PRODUCT';
const REQUEST_STORE_LOCATIONS = 'REQUEST_STORE_LOCATIONS';
const RECEIVE_STORE_LOCATIONS = 'RECEIVE_STORE_LOCATIONS';
const CLEAR_DATA = 'CLEAR_PRODUCT_DATA';

export default {
  REQUEST_PRODUCT,
  RECEIVE_PRODUCT,
  REQUEST_STORE_LOCATIONS,
  RECEIVE_STORE_LOCATIONS,
  CLEAR_DATA,
}
