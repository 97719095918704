import { connect } from 'react-redux';
import HomeComponent from './HomeComponent';
import { homeOperations } from './duck';

const mapStateToProps = state => {
  const { count } = state.home;
  return { currentCount: count }
};

const mapDispatchToProps = dispatch => {
  const onIncrementClick = () => dispatch(homeOperations.incrementCount(1));
  const onDecrementClick = () => dispatch(homeOperations.decrementCount(1));

  return {
    onIncrementClick,
    onDecrementClick
  }
};

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);

export default HomeContainer;
