import { connect } from 'react-redux';
import PageComponent from './PageComponent';
import { pageOperations } from './duck';

const mapStateToProps = state => {
  const { loading, page } = state.page;
  return { loading, page }
};

const PageContainer = connect(
  mapStateToProps,
  {
    fetchPage: pageOperations.fetchPage
  }
)(PageComponent);

export default PageContainer;
