export const tableData = [
  {
    header: ['App & Website Advertising', 'Basic', 'Foundation', 'Awareness', 'Reach'],
    rows: [
      ['Total creatives on rotation', '1', '2', '4', '12'],
      ['Total product categories to advertise your creatives on', '1', '2', '4', '6'],
      ['Choose States or Territories to advertise in', '1', '2', '4', 'All'],
      ['Advertise on how to build a list', 'Yes', 'Yes', '', ''],
      ['Advertise on Service pages', 'Yes', 'Yes', '', ''],
      ['Advertise on How to add MyScript', '', '', 'Yes', 'Yes'],
      ['Advertise on State pages', '', '', 'Yes', 'Yes'],
    ]
  },
  {
    header: ['Newsletter Advertising', 'Basic', 'Foundation', 'Awareness', 'Reach'],
    rows: [
      ['Display your logo sponsor on our Email Newsletters', '1', '2', '4', '12'],
      ['Quarterly Newsletter to Chemists', '1', '2', '4', '6'],
      ['Quarterly Newsletter to GPs', '1', '2', '4', 'All'],
      ['Quarterly Newsletter to Services and Government Organisations', '1', '2', '4', 'All'],
    ]
  },
  {
    header: ['Email Advertising', 'Basic', 'Foundation', 'Awareness', 'Reach'],
    rows: [
      ['Advertising on Autoresponder to Consumer for Price and Availability', '1', '2', '4', '12'],
      ['Advertising on Email list to Chemist for price and Availability', '1', '2', '4', '6'],
      ['Advertising on Autoresponder to Consumer for service Request', '1', '2', '4', 'All'],
      ['Advertising on Email to Chemist for service request', '1', '2', '4', 'All'],
      ['Advertising on Email for Scripts reminder 7 days', '1', '2', '4', 'All'],
    ]
  },
  {
    header: ['Digital Advertising', 'Basic', 'Foundation', 'Awareness', 'Reach'],
    rows: [
      ['Advertise your logo on Annual Reports', '', '', '', 'Yes'],
      ['Brand used in Chemist Select Video creatives', '', '', '', 'Yes'],
      ['Chemist Select Presentations/Flyers Collateral logo', '', '', '', 'Yes'],
    ]
  },
];
