import { connect } from 'react-redux';
import ProductComponent from './ProductComponent';
import { productOperations } from './duck';
import Creators from './duck/actions';

const mapStateToProps = state => {
  const { product, loading, storeLocations, loadingStoreLocations } = state.product;
  const { signedIn } = state.account;
  return { product, loading, signedIn, storeLocations, loadingStoreLocations }
};

const ProductContainer = connect(
  mapStateToProps,
  {
    clearData: Creators.clearData,
    fetchProduct: productOperations.fetchProduct,
    fetchStoreLocations: productOperations.fetchStoreLocations
  }
)(ProductComponent);

export default ProductContainer;
