import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import api from '../../apiService';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 8,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginBottom: theme.spacing.unit * 3,
  }
});


class ContactForm extends Component {
  state = {
    firstName: '',
    firstNameError: '',
    lastName: '',
    lastNameError: null,
    email: '',
    emailError: null,
    mobile: '',
    mobileError: null,
    complete: false,
  };

  handleSubmit(event) {
    this.setState({loading: true});
    event.preventDefault();
    this.clearErrors();

    if (this.validate()) {
      api.post('/api/v1/advertising-contact/', {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        mobile: this.state.mobile,
      })
        .then(() => {
          this.setState({loading: false, complete: true});
        })
    }
  }

  clearErrors() {
    this.setState({
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      mobileError: null,
    });
  }

  validate() {
    let firstNameError = null;
    let lastNameError = null;
    let emailError = null;

    // Validate first name
    if (!this.state.firstName) {
      firstNameError = 'Please enter your first name';
    }

    // Validate last name
    if (!this.state.lastName) {
      lastNameError = 'Please enter your last name';
    }

    // Validate email
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.email)) {
      emailError = 'Please enter a valid email address';
    }

    if (firstNameError || lastNameError || emailError) {
      this.setState({
        firstNameError,
        lastNameError,
        emailError,
      });
      return false;
    }
    return true;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, [`${prop}Error`]: null });
  };


  renderFirstNameError() {
    if (!!this.state.firstNameError) {
      return (
        <FormHelperText id="email-text">{this.state.firstNameError}</FormHelperText>
      );
    } else if (!!this.props.firstNameError) {
      return (
        <FormHelperText id="email-text">{this.props.firstNameError}</FormHelperText>
      );
    }
  }

  renderLastNameError() {
    if (!!this.state.lastNameError) {
      return (
        <FormHelperText id="email-text">{this.state.lastNameError}</FormHelperText>
      );
    } else if (!!this.props.lastNameError) {
      return (
        <FormHelperText id="email-text">{this.props.lastNameError}</FormHelperText>
      );
    }
  }

  renderEmailError() {
    if (!!this.state.emailError) {
      return (
        <FormHelperText id="email-text">{this.state.emailError}</FormHelperText>
      );
    } else if (!!this.props.emailError) {
      return (
        <FormHelperText id="email-text">{this.props.emailError}</FormHelperText>
      );
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.complete) {
      return (
        <Typography>Thanks for your enquiry, we will be in touch shortly.</Typography>
      );
    }

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography variant="h4">Chat to us about advertising</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => this.handleSubmit(event)}
            noValidate
          >
            <FormControl
              className={classes.field}
              error={!!this.state.firstNameError || !!this.props.firstNameError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="firstName">First Name</InputLabel>
                <Input
                  id="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}
                  inputProps={{
                    required: true,
                    type: 'text'
                  }}
                />
              {this.renderFirstNameError()}
            </FormControl>

            <FormControl
              className={classes.field}
              error={!!this.state.lastNameError || !!this.props.lastNameError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <Input
                  id="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                  inputProps={{
                    required: true,
                    type: 'text'
                  }}
                />
              {this.renderLastNameError()}
            </FormControl>

            <FormControl
              className={classes.field}
              error={!!this.state.emailError || !!this.props.emailError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                value={this.state.email}
                onChange={this.handleChange('email')}
                inputProps={{
                  required: true,
                  type: 'email'
                }}
                autoComplete="email"
              />
              {this.renderEmailError()}
            </FormControl>

            <FormControl
              className={classes.field}
              required={false}
              fullWidth
            >
              <InputLabel htmlFor="mobile">Mobile</InputLabel>
              <Input
                id="mobile"
                value={this.state.mobile}
                onChange={this.handleChange('mobile')}
                inputProps={{
                  required: false,
                  type: 'text'
                }}
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.props.loading}
            >
              { this.props.loading ? 'Sending...' : 'Get in touch' }
            </Button>

          </form>
        </Paper>
      </main>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
