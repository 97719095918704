import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ListIcon from '@material-ui/icons/List';
// import StarIcon from '@material-ui/icons/Star';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { push } from 'react-router-redux';
import { Helmet } from 'react-helmet';
import ResponsiveEmbed from 'react-responsive-embed'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SearchIcon from '@material-ui/icons/Search';
import ListItemText from '@material-ui/core/ListItemText';
import ReactGA from 'react-ga';
import CompareIcon from './images/comapare-icon-purple.svg';

// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ListSubheader from '@material-ui/core/ListSubheader';
// import IconButton from '@material-ui/core/IconButton';
// import InfoIcon from '@material-ui/icons/Info';

import bg from './images/homepage-banner.jpg';
import whyus from './images/why-use-us.jpg';
import store from '../../Store';
import RoundedButton from '../core/RoundedButton';
import { reviewData, categoryList } from './HomeData';
import appStore from "../core/images/apple-badge.svg";
import playStore from "../core/images/google-play-badge.png";
import chemistSelectLogo from "../core/images/chemist-select-logo-purple-border.png";


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  logo: {
    width: 300,
    alignSelf: 'center',
  },
  menuButton: {},
  margin: {
    margin: theme.spacing.unit,
  },
  bgOverlay: {
    background: fade(theme.palette.common.white, 0.75),
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bg: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: '30% 65%',
    minHeight: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  paper: {
    background: theme.palette.common.white,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    alignSelf: 'center'
  },
  button: {
    margin: theme.spacing.unit,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '1.8rem',
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '1.8rem',
    marginBottom: theme.spacing.unit * 4,
  },
  searchButton: {
    marginTop: 15,
  },
  browseButton: {
    margin: theme.spacing.unit * 2,
    padding: 25,
  },
  browseContainer: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: 50,
    paddingBottom: 50,
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.text.secondary,
  },
  contentContainer: {
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 50,
    paddingBottom: 50,
    textAlign: 'center',
  },
  greyContainer: {
    backgroundColor: theme.palette.grey['100'],
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 50,
    paddingBottom: 50,
  },
  mainContent: {
    fontSize: '1.5rem',
  },
  browseContent: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  browseHeading: {
    fontSize: '1rem',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    fontWeight: '900',
  },
  featureContainer: {
    // padding: theme.spacing.unit * 2,
  },
  featureHeading: {
    fontWeight: '900',
    fontSize: '1.5rem',
  },
  featureContent: {
    fontSize: '1rem',
  },
  fastCompareContainer: {
    background: theme.palette.primary.main,
    height: 400
  },
  roundedInput: {
    borderRadius: 25,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey['300']}`,
    padding: '14px 16px',
  },
  submitButtonContainer: {
    position: 'absolute',
    top: -2,
    right: -2
  },
  primaryText: {
    color: theme.palette.primary.main
  },
  leftRightPadding: {
    // paddingLeft: 24,
    // paddingRight: 24,
    textAlign: 'center'
  },
  videoContainer: {
    paddingBottom: 50,
  },
  checkIconStyle: {
    fontSize: 32,
    color: theme.palette.primary.main
  },
  slimListItem: {
    paddingTop: 4,
    paddingBottom: 4
  },

  reviewContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    // justifyContent: 'space-around',
    overflowX: 'auto',
    backgroundColor: theme.palette.background.paper,
    // paddingLeft: theme.spacing.unit * 2,
    // paddingRight: theme.spacing.unit * 2,
    paddingTop: 50,
    paddingBottom: 50,
  },
  reviewCard: {
    flex: '0 0 auto',
    width: 300,
    minHeight: 300,
    padding: theme.spacing.unit * 2,
    margin: theme.spacing.unit * 2,
    background: theme.palette.grey['100']
  },
  starContainer: {
    color: 'gold'
  },
  reviewHeaderContainer: {
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    paddingTop: 50,
    textAlign: 'center',
  },
  compareIcon: {
    marginTop: -25,
    marginBottom: -25,
  },
  gridList: {
    width: '100%',
    height: 'auto',
  },
  findOutMoreButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});


class HomeComponent extends Component {
  state = {searchTerm: ''};

  renderHomeNavButton(item) {
    const {button} = this.props.classes;
    const {id, linkURI, title} = item;
    return (
      <Button
        key={id}
        className={button}
        color="primary"
        component={Link}
        to={`/browse${linkURI}`}
        variant="contained"
      >
        {title}
      </Button>
    );
  }

  renderHomeNavButtons() {
    return (
      categoryList.map(item => {
        return (
          this.renderHomeNavButton(item)
        );
      })
    );
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.searchTerm) {
      ReactGA.event({
        category: 'Search',
        action: 'Banner search',
        label: this.state.searchTerm
      });
      store.dispatch(push(`/search/${this.state.searchTerm}`));
    }
  }

  render() {
    const {classes} = this.props;

    return (
      <div>
        <Helmet>
          <title>Chemist Select - Download the App Today</title>
          <meta name="description" content="Compare prices then have a conversation with your local chemist as to product availability and options. Download for FREE. Experience the convenience and savings."/>
        </Helmet>
        <div>
          <div className={classes.bg}>
            {/*<div className={classes.bgOverlay} />*/}
            <Grid container spacing={0} justify={'center'}>

              <Grid item lg={4} md={6} xs={11}>
                <Paper className={classes.paper}>
                  <Grid container spacing={8} justify={'center'}>
                    <Grid item xs={12}>
                      <Typography variant="h5" className={classes.heading}>
                        Search Compare Select
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <form onSubmit={(event) => this.handleSubmit(event)}>
                        <FormControl fullWidth={true}>
                          <div style={{ position: 'relative', paddingBottom: 20 }}>
                            <TextField
                              placeholder={'Search...'}
                              label=''
                              fullWidth={true}
                              value={this.state.searchTerm}
                              onChange={(event) => this.setState({searchTerm: event.target.value})}
                              InputProps={{
                                disableUnderline: true,
                                classes: {
                                  input: classes.roundedInput,
                                },
                              }}
                            />
                            <div className={classes.submitButtonContainer}>

                              <RoundedButton
                                type='submit'
                                variant="contained"
                                color="secondary"
                              >
                                Search
                              </RoundedButton>
                            </div>
                          </div>
                        </FormControl>
                      </form>
                    </Grid>
                    <Grid item xs={12}>
                      <a href={"https://apps.apple.com/us/app/chemist-select/id1496941787"}>
                        <img src={appStore} height={66} alt='Get on App Store' style={{marginBottom: 16}}/>
                      </a>
                      <a href={"https://play.google.com/store/apps/details?id=au.com.chemistselect.chemistselect"}>
                        <img src={playStore} height={98} alt='Get on Play Store' />
                      </a>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

            </Grid>
          </div>
        </div>

        <div className={classes.greyContainer}>
          <Grid container justify={"center"}>
            <Grid item md={8} xs={12}>
              <Typography paragraph variant={'h6'} className={classes.subHeading}>
                Featured on Channel Nine News
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <Grid container justify={"center"}>
                <Grid item md={6} xs={12}>
                  <div className={classes.videoContainer}>
                    <ResponsiveEmbed src='https://www.youtube.com/embed/Ve9fy9iYrDs' allowFullScreen />
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md={8} xs={12}>
              <Typography paragraph variant={'h6'} className={classes.subHeading}>
              <img src={chemistSelectLogo} className={classes.logo} alt='Chemist Select Logo' />
              </Typography>
              <Typography paragraph variant={'h6'} className={classes.subHeading}>
                Trusted by Australian Consumers
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <div className={classes.leftRightPadding}>
                <Typography paragraph color={'textSecondary'} className={classes.mainContent}>
                  Find better prices
                </Typography>
                <Typography paragraph color={'textSecondary'} className={classes.mainContent}>
                  Manage Prescriptions
                </Typography>
                <Typography paragraph color={'textSecondary'} className={classes.mainContent}>
                  Connect with Health Services
                </Typography>
                <Typography paragraph color={'primary'} className={classes.featureContent}>
                  Designed for better health outcomes at less cost.
                </Typography>
                <Typography paragraph color={'primary'} className={classes.featureContent}>
                  Chemist Select is FREE and INDEPENDENT of all Australian Chemists
                </Typography>
              </div>
            </Grid>
            <Grid item md={8} xs={12}>
              <div className={classes.findOutMoreButton}>
                <RoundedButton
                  variant="contained"
                  color="secondary"
                  onClick={() => store.dispatch(push('/about'))}
                >
                  Find out more
                </RoundedButton>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.contentContainer}>
          <Grid container justify={"center"}>
            <Grid item md={8} xs={12} className={classes.featureContainer}>
              <Grid container justify={"center"}>
                <Grid item md={4} xs={12}>
                  <SearchIcon color={"primary"} style={{fontSize: 100}}/>
                  <Typography paragraph color={'primary'} variant={'h6'} className={classes.featureHeading}>
                    Search
                  </Typography>
                  <Typography paragraph color={'textSecondary'} className={classes.featureContent}>
                    Search for your product
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  {/*<ListIcon color={"primary"} style={{fontSize: 100}}/>*/}
                  <div className={classes.compareIcon}>
                    <img src={CompareIcon} width={150} />
                  </div>
                  <Typography paragraph color={'primary'} variant={'h6'} className={classes.featureHeading}>
                    Compare
                  </Typography>
                  <Typography paragraph color={'textSecondary'} className={classes.featureContent}>
                    Compare prices
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ShoppingCartIcon color={"primary"} style={{fontSize: 100}}/>
                  <Typography paragraph color={'primary'} variant={'h6'} className={classes.featureHeading}>
                    Select
                  </Typography>
                  <Typography paragraph color={'textSecondary'} className={classes.featureContent}>
                    Select your product
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        {/*<div className={classes.greyContainer}>*/}
        {/*  <Grid container justify={"center"}>*/}
        {/*    <Grid item md={8} xs={12}>*/}
        {/*      <Grid container>*/}
        {/*        <Grid item md={6} xs={12}>*/}
        {/*          <div className={classes.leftRightPadding}>*/}
        {/*            <img src={whyus} alt='why use chemist select' width='100%'/>*/}
        {/*          </div>*/}
        {/*        </Grid>*/}
        {/*        <Grid item md={6} xs={12}>*/}
        {/*          <Typography paragraph variant={'h6'} className={classes.subHeading}>*/}
        {/*            What is Chemist Select and how does it help save me money?*/}
        {/*          </Typography>*/}
        {/*          <List>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Compare one product or your entire shopping list" />*/}
        {/*            </ListItem>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Fast compare any item anytime, anywhere" />*/}
        {/*            </ListItem>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Find out which chemist is actually the cheapest" />*/}
        {/*            </ListItem>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Fast change your shopping list and re compare" />*/}
        {/*            </ListItem>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Find out where to purchase or order online" />*/}
        {/*            </ListItem>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Send list directly to any chemist(s)" />*/}
        {/*            </ListItem>*/}
        {/*            <ListItem className={classes.slimListItem}>*/}
        {/*              <ListItemIcon>*/}
        {/*                <CheckIcon color='secondary' className={classes.checkIconStyle} />*/}
        {/*              </ListItemIcon>*/}
        {/*              <ListItemText primary="Download the App from the App store or Google Play" />*/}
        {/*            </ListItem>*/}
        {/*          </List>*/}
        {/*        </Grid>*/}
        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</div>*/}

        <div className={classes.greyContainer}>
          <Grid container justify={"center"}>
            <Grid item md={8} xs={12}>
              <Typography paragraph variant={'h6'} className={classes.subHeading}>
                Download the Chemist Select App now and get started.
              </Typography>
              <Grid container spacing={8} justify={'center'}>
                <div style={{testAlign: 'center'}}>
                  <Grid item xs={12} style={{alignSelf: 'center'}}>
                    <a href={"https://apps.apple.com/us/app/chemist-select/id1496941787"}>
                      <img src={appStore} height={66} alt='Get on App Store' style={{marginBottom: 16}}/>
                    </a>
                    <a href={"https://play.google.com/store/apps/details?id=au.com.chemistselect.chemistselect"}>
                      <img src={playStore} height={98} alt='Get on Play Store' />
                    </a>
                  </Grid>
                </div>
              </Grid>
              {/*<Typography paragraph color={'textSecondary'} className={classes.browseContent}>*/}
              {/*  Chemist Select is made by Australians for Australians*/}
              {/*</Typography>*/}
              {/*<Typography paragraph className={classes.browseHeading}>*/}
                {/*Browse by Category*/}
              {/*</Typography>*/}
            </Grid>
          </Grid>

          {/*<Grid container>*/}
            {/*<Grid item>*/}
              {/*<GridList cellHeight={200} cols={5} className={classes.gridList}>*/}
                {/*{tileData.map(tile => (*/}
                  {/*<GridListTile key={tile.img}>*/}
                    {/*<img src={tile.img} alt={tile.title} />*/}
                    {/*<GridListTileBar*/}
                      {/*title={tile.title}*/}
                      {/*subtitle={<span>by: {tile.author}</span>}*/}
                      {/*actionIcon={*/}
                        {/*<IconButton className={classes.icon}>*/}
                          {/*<InfoIcon />*/}
                        {/*</IconButton>*/}
                      {/*}*/}
                    {/*/>*/}
                  {/*</GridListTile>*/}
                {/*))}*/}
              {/*</GridList>*/}
            {/*</Grid>*/}
          {/*</Grid>*/}

        </div>

        {/*<div className={classes.reviewHeaderContainer}>*/}
        {/*  <Grid container justify={"center"}>*/}
        {/*    <Grid item md={8} xs={12}>*/}
        {/*      <Typography paragraph variant={'h6'}>*/}
        {/*        How users rated our service*/}
        {/*      </Typography>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</div>*/}

        {/*<div className={classes.reviewContainer}>*/}
        {/*  {reviewData.map(review => (*/}
        {/*    <Paper className={classes.reviewCard} key={review.title}>*/}
        {/*      <Typography paragraph variant={'h6'} color={'primary'}>{review.title}</Typography>*/}
        {/*      <Typography paragraph color={'textSecondary'}>{review.content}</Typography>*/}
        {/*      <Typography>{review.author}</Typography>*/}
        {/*      <div className={classes.starContainer}>*/}
        {/*        <StarIcon/>*/}
        {/*        <StarIcon/>*/}
        {/*        <StarIcon/>*/}
        {/*        <StarIcon/>*/}
        {/*        <StarIcon/>*/}
        {/*      </div>*/}
        {/*    </Paper>*/}
        {/*  ))}*/}
        {/*</div>*/}

      </div>
    );
  }
}

HomeComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeComponent);
