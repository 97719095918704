import axios from 'axios';
import Cookies from 'js-cookie'
import { push } from 'react-router-redux';
import { accountOperations } from './app/account/duck';
import store from './Store';


class APIService {
  constructor() {
    let service = axios.create({});
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
  }

  setup() {
    if (this.addTokenToAxios()) {
      store.dispatch(accountOperations.getUserAndSignIn());
    }
  }

  setToken(token) {
    Cookies.set('token', token);
    this.addTokenToAxios();
  }

  removeToken() {
    Cookies.remove('token');
    this.removeTokenFromAxios();
  }

  addTokenToAxios() {
    const token = Cookies.get('token');
    if (token) {
      this.service.defaults.headers.common['Authorization'] = `Token ${token}`;
      return true;
    }
    return false;
  }

  removeTokenFromAxios() {
    delete axios.defaults.headers.common['Authorization'];
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    switch (error.response.status) {
      case 401:
        this.removeToken();
        store.dispatch(push('/account/sign-in'));
        break;
      case 404:
        store.dispatch(push('/404-not-found'));
        break;
      case 500:
        store.dispatch(push('/500-server-error'));
        break;
      default:
        return Promise.reject(error);
    }
  };

  get(path, params={}) {
    this.addTokenToAxios();
    return this.service.get(path, params);
  }

  post(path, payload={}) {
    return this.service.post(path, payload);
  }

  delete(path) {
    return this.service.delete(path);
  }
}

export default new APIService();
