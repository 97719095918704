import React, { Component } from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from'@material-ui/core/Button';
import {push} from "react-router-redux";
import ReactGA from 'react-ga';

import api from '../../apiService';
import store from "../../Store";
import RoundedButton from './RoundedButton';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  iconSmall: {
    fontSize: 20,
    marginLeft: theme.spacing.unit,
  },
});


class WatchlistButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      force: false,
      disabled: false,
      itemId: props.itemId ? props.itemId : null,
      type: props.itemId ? 'remove' : 'add',
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { itemId } = props;
    if (itemId) {
      if (itemId !== state.itemId) {
        return { itemId: itemId };
      }
    }
    return null;
  }

  handleClick() {
    const { signedIn, productId, from } = this.props;
    const { itemId, type } = this.state;

    if (signedIn && itemId && type === 'remove') {
      this.setState({ disabled: true });
      api.delete(`/api/v1/watchlist-items/${itemId}/`)
        .then((response) => {
          ReactGA.event({
            category: 'User',
            action: 'Remove from list',
            label: from
          });
          this.setState({disabled: false, itemId: null, type: 'add'})
        })
        .catch((error) => {
          console.log(error);
        });
    }
    else if (signedIn) {
      this.setState({ disabled: true });
      api.post('/api/v1/watchlist-items/', {product: productId})
        .then((response) => {
          ReactGA.event({
            category: 'User',
            action: 'Add to watch list',
            label: from
          });
          this.setState({disabled: false, itemId: response.data.id, type: 'remove'})
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      store.dispatch(push('/account/sign-up'));
    }
  }

  render() {
    const { signedIn, classes, size, rounded } = this.props;
    const { itemId, type } = this.state;
    if (signedIn && itemId && type === 'remove') {
      if (rounded) {
        return (
          <RoundedButton
            size={size}
            color="default"
            variant="contained"
            onClick={() => this.handleClick()}
            disabled={this.state.disabled}
          >
            Remove from List
            <RemoveIcon className={classes.iconSmall}/>
          </RoundedButton>
        );
      } else {
        return (
          <Button
            size={size}
            color="default"
            variant="contained"
            onClick={() => this.handleClick()}
            disabled={this.state.disabled}
          >
            Remove from List
            <RemoveIcon className={classes.iconSmall}/>
          </Button>
        );
      }
    } else {
      if (rounded) {
        return (
          <RoundedButton
            size={size}
            color="secondary"
            variant="contained"
            onClick={() => this.handleClick()}
            disabled={this.state.disabled}
          >
            Add to Watch List
            <AddIcon className={classes.iconSmall}/>
          </RoundedButton>
        );
      } else {
        return (
          <Button
            size={size}
            color="secondary"
            variant="contained"
            onClick={() => this.handleClick()}
            disabled={this.state.disabled}
          >
            Add to Watch List
            <AddIcon className={classes.iconSmall}/>
          </Button>
        );
      }
    }
  }
}

WatchlistButton.propTypes = {
  classes: PropTypes.object.isRequired,
  signedIn: PropTypes.bool.isRequired,
  productId: PropTypes.string.isRequired,
  rounded: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  itemId: PropTypes.string,
};

WatchlistButton.defaultProps = {
  rounded: false,
  size: 'small'
};

export default withStyles(styles)(WatchlistButton);