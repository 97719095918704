import types from './types.js';

const requestList = () => {
  return({
    type: types.REQUEST_LIST
  });
};

const receiveList = (list) => {
  return({
    type: types.RECEIVE_LIST,
    payload: list
  });
};

const updateList = (list) => {
  return({
    type: types.UPDATE_LIST,
    payload: list
  });
};

const requestMoreList = () => {
  return({
    type: types.REQUEST_MORE_LIST
  });
};

const receiveMoreList = (response) => {
  return({
    type: types.RECEIVE_MORE_LIST,
    payload: response
  })
};

export default {
  requestList,
  receiveList,
  updateList,
  requestMoreList,
  receiveMoreList,
}
