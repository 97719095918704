import React from 'react';
import 'typeface-roboto';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import ReactGA from 'react-ga'

import store, { history } from './Store';
import './index.css';
import App from './app/App';
import { unregister } from './registerServiceWorker';
import ScrollToTop from './app/core/ScrollToTop';
// import registerServiceWorker from './registerServiceWorker';

ReactGA.initialize('UA-132413433-1', {
  debug: process.env.NODE_ENV === 'development',
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

unregister();
// registerServiceWorker();
// https://stackoverflow.com/questions/45663796/setting-service-worker-to-exclude-certain-urls-only?rq=1