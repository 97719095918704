import ReactGA from 'react-ga';
import Creators from './actions';
import api from '../../../apiService';

const requestListAction = Creators.requestList;
const receiveListAction = Creators.receiveList;
const updateListAction = Creators.updateList;
const requestMoreListAction = Creators.requestMoreList;
const receiveMoreListAction = Creators.receiveMoreList;

const fetchList = () => {
  return (dispatch) => {
    dispatch(requestListAction());
    api.get('/api/v1/watchlist-items/')
      .then(response => {
        dispatch(receiveListAction(response.data))
      })
      .catch(error => {
        console.log(error);
      });
  }
};

const fetchNextListPage = (url) => {
  return (dispatch) => {
    dispatch(requestMoreListAction());
    api.get(url)
      .then(response => {
        dispatch(receiveMoreListAction(response.data))
      })
      .catch(error => {
        console.log(error);
      });
  }
};

const removeListItem = (id) => {
  return (dispatch, getState) => {
    const newList = getState().list.list.filter((item) => {return item.id !== id});
    ReactGA.event({
      category: 'User',
      action: 'Remove from list',
      label: 'My list'
    });
    dispatch(updateListAction(newList));
    api.delete(`/api/v1/watchlist-items/${id}/`);
  }
};

export default { fetchList, removeListItem, fetchNextListPage }
