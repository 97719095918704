import types from './types';

const INITIAL_STATE = {
  products: [],
  count: null,
  next: null,
  loading: true,
  category: null
};

const categoryReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.REQUEST_PRODUCTS:
      return {
        ...state,
        loading: true
      };

    case types.RECEIVE_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...action.payload.results],
        count: action.payload.count,
        next: action.payload.next,
        loading: false
      };

    case types.RECEIVE_CATEGORY:
      return {
        ...state,
        category: action.payload
      };

    case types.CLEAR_PRODUCTS:
      return {
        ...state,
        products: []
      };

    case types.CLEAR_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default categoryReducer;
