import types from './types';

const INITIAL_STATE = {
  loading: true,
  list: null,
  next: null,
  loadingMore: false,
};

const listReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.REQUEST_LIST:
      return { ...state, loading: true };

    case types.REQUEST_MORE_LIST:
      return { ...state, loadingMore: true };

    case types.RECEIVE_LIST:
      return {
        ...state,
        list: action.payload.results,
        next: action.payload.next,
        loading: false
      };

    case types.UPDATE_LIST:
      return {
        ...state,
        list: action.payload,
      };

    case types.RECEIVE_MORE_LIST:
      return {
        ...state,
        next: action.payload.next,
        list: [...state.list, ...action.payload.results],
        loadingMore: false
      };

    default:
      return state;
  }
};

export default listReducer;
