import types from './types';

const INITIAL_STATE = {
  loading: true,
  category: null
};

const primaryCategoryReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.REQUEST_CATEGORY:
      return INITIAL_STATE;

    case types.RECEIVE_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default primaryCategoryReducer;
