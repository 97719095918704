import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Dotdotdot from 'react-dotdotdot';
import ContentLoader from 'react-content-loader';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  bgOverlay: {
    background: fade(theme.palette.primary.dark, 0.75),
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bg: {
    backgroundSize: 'cover',
    backgroundPosition: '20% 50%',
    minHeight: 200,
    position: 'relative',
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    background: fade(theme.palette.common.white, 0.75),
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '2.5rem',
    color: theme.palette.common.white
  },
  subHeading: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: theme.palette.common.white
  },
  card: {
    width: 325,
    height: 310,
    margin: theme.spacing.unit * 2,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      background: fade(theme.palette.common.white, 0.75),
    },
  },
  media: {
    height: 0,
    paddingTop: '70%',
  },
  subCatTitle: {
    textDecoration: 'none',
    fontSize: '1.5rem',
    color: theme.palette.text.primary,
  }
});


const CategoryLoader = props => (
  <ContentLoader
		height={310}
		width={330}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<rect x="22.5" y="5" rx="5" ry="5" width="285" height="230" />
		<rect x="22.5" y="250" rx="5" ry="5" width="285" height="50" />
	</ContentLoader>
);


class PrimaryCategoryComponent extends Component {
  constructor(props) {
    super(props);
    // Initially fetch the data
    this.fetchCategoryData();
  }

  componentDidUpdate(prevProps) {
    // Fetch the data when the url changes
    if (this.props.match.url !== prevProps.match.url) {
      this.fetchCategoryData();
    }
  }

  fetchCategoryData() {
    const { categorySlug } = this.props.match.params;
    this.props.fetchCategory(categorySlug);
  }

  renderHead() {
    const { category, loading } = this.props;
    if (!loading) {
      return (
        <Helmet>
          <title>Compare cheapest {category.title} products | Chemist Select</title>
          <meta
            name="description"
            content={`Save on ${category.title} products by comparing prices at Chemist Select. Find the best deals from trusted chemists and shop smart for your health needs.`} />
        </Helmet>
      );
    }
  }

  handleClickTracking(title) {
    ReactGA.event({
      category: 'Outbound Link',
      action: 'Refine',
      label: title
    });
  }

  renderSubCategories() {
    const { loading, category, classes } = this.props;
    const arr = Array.from(Array(12).keys());
    if(!loading) {
      return (
        category.children.map((child) => {
          console.log(child.title);
          return (
            <div key={child.slug}>
              <Card className={classes.card}>

                <Link to={child.uri} className={classes.link} onClick={() => this.handleClickTracking(child.title)}>
                {child.image && child.image.grid ?
                  <CardMedia
                    className={classes.media}
                    image={child.image.grid}
                    title={child.title}
                  />
                  :
                  <CardMedia
                    className={classes.media}
                    // image="https://via.placeholder.com/325x230?text=Chemist Select"
                    title={child.title}
                  />
                }
                </Link>

                <CardContent className={classes.subCatTitle}>
                  <Link to={child.uri} className={classes.link}>
                    <Typography gutterBottom className={classes.subCatTitle} component="p" align={'center'}>
                      <Dotdotdot clamp={1}>
                        {child.title}
                      </Dotdotdot>
                    </Typography>
                  </Link>
                </CardContent>

              </Card>
            </div>
          );
        })
      );
    } else {
      return (
        arr.map((i) => {
          return(
            <div key={i}>
              <Card className={classes.card}>
                <CategoryLoader />
              </Card>
            </div>
          );
        })
      );
    }
  }

  render() {
    const { category, classes } = this.props;
    return (
      <div>
        {this.renderHead()}
        <div>
          <div className={classes.bg} style={{
            backgroundImage: `url(${category && category.image ? category.image.banner : "''"})`,
            }}
          >
            <div className={classes.bgOverlay} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
              <Typography variant="h1" className={classes.heading}>
                {category ? `Compare cheapest ${category.title} product prices` : '...'}
              </Typography>
              <Typography className={classes.subHeading}>
                {category ? category.description : ''}
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <Grid container justify={'center'}>
            {this.renderSubCategories()}
          </Grid>
          <Grid container justify={'center'}>
            <Grid item md={12}>
            </Grid>
          </Grid>
        </div>

      </div>
    );
  }
}

PrimaryCategoryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrimaryCategoryComponent);
