const REQUEST_LIST = 'REQUEST_LIST';
const RECEIVE_LIST = 'RECEIVE_LIST';
const UPDATE_LIST = 'UPDATE_LIST';
const REQUEST_MORE_LIST = 'REQUEST_MORE_LIST';
const RECEIVE_MORE_LIST = 'RECEIVE_MORE_LIST';

export default {
  REQUEST_LIST,
  RECEIVE_LIST,
  UPDATE_LIST,
  REQUEST_MORE_LIST,
  RECEIVE_MORE_LIST,
}