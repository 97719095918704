import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import {push} from 'react-router-redux';
// import store from '../../../../Store';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginBottom: theme.spacing.unit * 3,
  }
});


class SignUpForm extends Component {
  state = {
    firstName: '',
    firstNameError: '',
    lastName: '',
    lastNameError: null,
    email: '',
    emailError: null,
    password: '',
    passwordError: null,
    mobile: '',
    mobileError: null,
    gender: '',
    genderError: null,
    ageGroup: '',
    ageGroupError: null,
    checkbox: false,
    checkboxError: null,
    showPassword: false,
  };

  handleSubmit(event) {
    event.preventDefault();
    this.clearErrors();

    if (this.validate()) {
      this.props.onSubmit(
        this.state.firstName,
        this.state.lastName,
        this.state.email,
        this.state.password,
        this.state.mobile,
        this.state.gender,
        this.state.ageGroup,
      );
    }
  }

  clearErrors() {
    this.setState({
      firstNameError: null,
      lastNameError: null,
      emailError: null,
      passwordError: null,
      mobileError: null,
      genderError: null,
      ageGroupError: null,
      checkboxError: null,
    });
  }

  validate() {
    let firstNameError = null;
    let lastNameError = null;
    let emailError = null;
    let passwordError = null;
    let checkboxError = null;
    let genderError = null;
    let ageGroupError = null;

    // Validate first name
    if (!this.state.firstName) {
      firstNameError = 'Please enter your first name';
    }

    // Validate last name
    if (!this.state.lastName) {
      lastNameError = 'Please enter your last name';
    }

    // Validate email
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.email)) {
      emailError = 'Please enter a valid email address';
    }
    // Validate terms
    if (!this.state.checkbox) {
      checkboxError = 'You must agree to the Terms and Conditions and Privacy Policy to sign up.';
    }
    // Validate password
    if (!this.state.password) {
      passwordError = 'Please enter a password';
    }
    // Validate gender
    if (!this.state.gender) {
      genderError = 'Please select a gender';
    }
    // Validate age group
    if (!this.state.ageGroup) {
      ageGroupError = 'Please select an age group';
    }

    if (firstNameError || lastNameError || emailError || passwordError || checkboxError || genderError || ageGroupError) {
      this.setState({
        firstNameError,
        lastNameError,
        emailError,
        passwordError,
        checkboxError,
        ageGroupError,
        genderError,
      });
      return false;
    }
    return true;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, [`${prop}Error`]: null });
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked, [`${name}Error`]: null });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  renderCheckboxLabel() {
    return (
      <Typography>I have read and agree to the&nbsp;
        <Link to="/terms-and-conditions" target="_blank">
          Terms and Conditions
        </Link>
        &nbsp;& the&nbsp;
        <Link to="/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
      </Typography>
    );
  }

  renderFirstNameError() {
    if (!!this.state.firstNameError) {
      return (
        <FormHelperText id="email-text">{this.state.firstNameError}</FormHelperText>
      );
    } else if (!!this.props.firstNameError) {
      return (
        <FormHelperText id="email-text">{this.props.firstNameError}</FormHelperText>
      );
    }
  }

  renderLastNameError() {
    if (!!this.state.lastNameError) {
      return (
        <FormHelperText id="email-text">{this.state.lastNameError}</FormHelperText>
      );
    } else if (!!this.props.lastNameError) {
      return (
        <FormHelperText id="email-text">{this.props.lastNameError}</FormHelperText>
      );
    }
  }

  renderPasswordError() {
    if (!!this.state.passwordError) {
      return (
        <FormHelperText id="password-text">{this.state.passwordError}</FormHelperText>
      );
    } else if (!!this.props.passwordError) {
      return (
        <FormHelperText id="password-text">{this.props.passwordError}</FormHelperText>
      );
    }
  }

  renderEmailError() {
    if (!!this.state.emailError) {
      return (
        <FormHelperText id="email-text">{this.state.emailError}</FormHelperText>
      );
    } else if (!!this.props.emailError) {
      return (
        <FormHelperText id="email-text">{this.props.emailError}</FormHelperText>
      );
    }
  }

  renderGenderError() {
    if (!!this.state.genderError) {
      return (
        <FormHelperText id="email-text">{this.state.genderError}</FormHelperText>
      );
    } else if (!!this.props.genderError) {
      return (
        <FormHelperText id="email-text">{this.props.genderError}</FormHelperText>
      );
    }
  }

  renderAgeGroupError() {
    if (!!this.state.ageGroupError) {
      return (
        <FormHelperText id="email-text">{this.state.ageGroupError}</FormHelperText>
      );
    } else if (!!this.props.ageGroupError) {
      return (
        <FormHelperText id="email-text">{this.props.ageGroupError}</FormHelperText>
      );
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h4">Sign Up</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => this.handleSubmit(event)}
            noValidate
          >
            <FormControl
              className={classes.field}
              error={!!this.state.firstNameError || !!this.props.firstNameError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="firstName">First Name</InputLabel>
                <Input
                  id="firstName"
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}
                  inputProps={{
                    required: true,
                    type: 'text'
                  }}
                />
              {this.renderFirstNameError()}
            </FormControl>

            <FormControl
              className={classes.field}
              error={!!this.state.lastNameError || !!this.props.lastNameError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="lastName">Last Name</InputLabel>
                <Input
                  id="lastName"
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                  inputProps={{
                    required: true,
                    type: 'text'
                  }}
                />
              {this.renderLastNameError()}
            </FormControl>

            <FormControl
              className={classes.field}
              error={!!this.state.emailError || !!this.props.emailError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                value={this.state.email}
                onChange={this.handleChange('email')}
                inputProps={{
                  required: true,
                  type: 'email'
                }}
                autoComplete="email"
              />
              {this.renderEmailError()}
            </FormControl>

            <FormControl
              className={classes.field}
              required={true}
              error={!!this.state.passwordError || !!this.props.passwordError}
              fullWidth
            >
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {this.renderPasswordError()}
            </FormControl>

            <FormControl
              className={classes.field}
              required={false}
              fullWidth
            >
              <InputLabel htmlFor="mobile">Mobile</InputLabel>
              <Input
                id="mobile"
                value={this.state.mobile}
                onChange={this.handleChange('mobile')}
                inputProps={{
                  required: false,
                  type: 'text'
                }}
              />
            </FormControl>

            <FormControl
              className={classes.field}
              error={!!this.state.genderError || !!this.props.genderError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="gender">Gender</InputLabel>
              <Select
                id="gender"
                value={this.state.gender}
                onChange={this.handleChange('gender')}
                inputProps={{
                  name: 'gender',
                  required: true,
                }}
              >
                <MenuItem value={'Female'}>Female</MenuItem>
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
                <MenuItem value={'Prefer not to say'}>Prefer not to say</MenuItem>
              </Select>
              {this.renderGenderError()}
            </FormControl>

            <FormControl
              className={classes.field}
              error={!!this.state.ageGroupError || !!this.props.ageGroupError}
              required={true}
              fullWidth
            >
              <InputLabel htmlFor="age-group">Age group</InputLabel>
              <Select
                id="age-group"
                value={this.state.ageGroup}
                onChange={this.handleChange('ageGroup')}
                inputProps={{
                  name: 'ageGroup',
                  required: true,
                }}
              >
                <MenuItem value={'Under 18 years old'}>Under 18 years old</MenuItem>
                <MenuItem value={'18-24 years old'}>18-24 years old</MenuItem>
                <MenuItem value={'25-34 years old'}>25-34 years old</MenuItem>
                <MenuItem value={'35-44 years old'}>35-44 years old</MenuItem>
                <MenuItem value={'45-54 years old'}>45-54 years old</MenuItem>
                <MenuItem value={'55-64 years old'}>55-64 years old</MenuItem>
                <MenuItem value={'65-74 years old'}>65-74 years old</MenuItem>
                <MenuItem value={'75 years or older'}>75 years or older</MenuItem>
              </Select>
              {this.renderAgeGroupError()}
            </FormControl>

            <FormControl
              error={!!this.state.checkboxError}
              className={classes.field}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checkbox}
                    onChange={this.handleCheckChange('checkbox')}
                    value="agree"
                    color="primary"
                    required={false}
                  />
                }
                label={this.renderCheckboxLabel()}
              />
              {!!this.state.checkboxError ? <FormHelperText id="checkbox-text">{this.state.checkboxError}</FormHelperText> : null}
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.props.loading}
            >
              { this.props.loading ? 'Signing up...' : 'Sign up' }
            </Button>

          </form>
          <Typography>Already have an account... <Link to={'/account/sign-in'}>Login Here</Link></Typography>
        </Paper>
      </main>
    );
  }
}

SignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withStyles(styles)(SignUpForm);
