import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ContentLoader from "react-content-loader"
import { push } from 'react-router-redux';
import ReactGA from 'react-ga';

import { withStyles } from '@material-ui/core/styles';
import store from '../../Store';
import prescriptionAvatar from './images/rx-small.jpg';

const styles = theme => ({
  root: {
    margin: theme.spacing.unit * 2,
    position: 'relative',
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 25,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey['300']}`,
    padding: '10px 12px',
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 280
    }
  },
  searchIconWrapper: {
    // position: 'absolute',
    // right: 10,
    // top: -3
  },
  searchIcon: {
    position: 'absolute',
    // right: 10,
    // top: -3
  },
  iconButton: {
    height: 30,
    width: 30,
    position: 'absolute',
    right: 8,
    top: 6
  },
  popper: {
    zIndex: 1200,
    width: 400,
    [theme.breakpoints.down('md')]: {
      width: 280
    }
  },
  avatar: {
    width: 50,
    height: 50,
  },
  noProducts: {
    padding: 20,
  }
});


const AutoCompleteLoader = props => (
		<ContentLoader
		height={70}
		width={400}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<circle cx="42" cy="35" r="25" />
		<rect x="90" y="22.05" rx="15" ry="15" width="288" height="27" />
	</ContentLoader>
);


class AppSearchComponent extends Component {
  state = {searchTerm: '', anchorEl: null,};

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.searchTerm) {
      this.setState({anchorEl: null,});
      ReactGA.event({
        category: 'Search',
        action: 'Top bar search',
        label: this.state.searchTerm
      });
      store.dispatch(push(`/search/${this.state.searchTerm}`));
    }
  }

  handleChange(event) {
    const { value } = event.target;
    this.props.requestProductAutocomplete();
    if(value.length >= 2) {
      this.setState({ searchTerm: value, anchorEl: event.currentTarget });
      this.props.fetchAutoCompleteProducts(value);
    } else if (value.length === 0) {
      this.setState({searchTerm: value, anchorEl: null })
    } else {
      this.setState({searchTerm: value, anchorEl: event.currentTarget });
    }
  }

  handleAutoCompleteItemClick(product) {
    store.dispatch(push(`/product/${product}`));
  }

  handleAutocompleteClickAway = () => {
    this.setState({
      anchorEl: null,
    });
  };

  renderAvatar(product) {
    const { classes } = this.props;
    if (product.prescription) {
      return (
        <Avatar src={prescriptionAvatar} alt={product.title} className={classes.avatar}/>
      );
    }

    return (
      <Avatar src={product.primary_provider_product.images.length > 0 ? product.primary_provider_product.images[0].image.autocomplete : 'https://via.placeholder.com/50x50'} alt={product.title} className={classes.avatar}/>
    );

  }

  renderAutoTitle(product) {
    if (product.prescription) {
      return (
        <ListItemText primary={product.title} />
      );
    }
    return (
      <ListItemText primary={`${product.title} - $${product.primary_provider_product.current_price.price}`} />
    );
  }

  renderAutocompleteContent() {
    const { autocompleteProducts, classes, autocompleteLoading } = this.props;
    const arr = Array.from(Array(5).keys());
    if(autocompleteProducts.length > 0 && !autocompleteLoading) {
      return (
        <List>
          {
            autocompleteProducts.map(product => {
              return (
                <ListItem
                  key={product.slug}
                  button
                  divider
                  className={classes.listItem}
                  onClick={() => this.handleAutoCompleteItemClick(product.slug)}
                >
                  {this.renderAvatar(product)}
                  {this.renderAutoTitle(product)}
                </ListItem>
              );
            })
          }
        </List>
      );
    } else if (autocompleteProducts.length === 0 && !autocompleteLoading) {
      return (
        <div className={classes.noProducts}>
          <Typography>No products match your search.</Typography>
        </div>
      );
    }
    return (
      <div>
        {arr.map((i) => {
          return (
            <AutoCompleteLoader key={i} />
          );
        })}
      </div>
    );

  }

  render() {
    const { classes, width } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : null;

    return (
      <ClickAwayListener onClickAway={this.handleAutocompleteClickAway}>
        <div className={classes.root} style={{ display: isWidthUp('sm', width) ? 'block' : 'none' }}>
          <form onSubmit={(event) => this.handleSubmit(event)}>
            <TextField
              placeholder={'Search by product or ailment'}
              label=''
              value={this.state.searchTerm}
              onChange={(event) => this.handleChange(event)}
              onFocus={(event) => this.handleChange(event)}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: classes.bootstrapInput,
                },
              }}
            />
            <div className={classes.searchIconWrapper}>
              <IconButton type='submit' className={classes.iconButton}>
                <SearchIcon color={'primary'} className={classes.searchIcon} />
              </IconButton>
            </div>
          </form>

          <Popper id={id} className={classes.popper} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={100}>
                <Paper>
                  {this.renderAutocompleteContent()}
                </Paper>
              </Fade>
            )}
          </Popper>

        </div>
      </ClickAwayListener>
    );
  }
}

AppSearchComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

export default withStyles(styles)(withWidth()(AppSearchComponent));
