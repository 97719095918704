import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  button: {
    borderRadius: 25,
    padding: '6px 16px',
    margin: theme.spacing.unit,
    boxShadow: 'none',
  },
});

const RoundedButton = ({classes, otherClasses, ...other}) => {
  return (
    <Button className={classes.button} classes={otherClasses} { ...other } />
  );
};

RoundedButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoundedButton);
