import { connect } from 'react-redux';
import AppSearchComponent from './AppSearchComponent';
import { coreOperations } from './duck';

const mapStateToProps = state => {
  const { autocompleteLoading, autocompleteProducts } = state.core;
  return { autocompleteLoading, autocompleteProducts }
};

const AppSearchContainer = connect(
  mapStateToProps,
  {
    fetchAutoCompleteProducts: coreOperations.fetchAutoCompleteProducts,
    requestProductAutocomplete: coreOperations.requestProductAutocomplete,
  }
)(AppSearchComponent);

export default AppSearchContainer;
