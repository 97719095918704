import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import api from "../../../../apiService";
// import {push} from 'react-router-redux';
// import store from '../../../../Store';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginBottom: theme.spacing.unit * 3,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '5rem',
    paddingTop: theme.spacing.unit * 8,
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '2rem',
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  }
});


class ResetPasswordComponent extends Component {
  state = {
    password: '',
    passwordError: null,
    showPassword: false,
    showSuccess: false,
    showFailure: false,
    loading: false,
  };

  handleSubmit(event) {
    const { uid, token } = this.props.match.params;
    event.preventDefault();
    this.clearErrors();
    this.setState({loading: true});

    if (this.validate()) {
      api.post('/api/v1/auth/password/reset/confirm/', { uid, token, new_password: this.state.password  })
        .then((response) => {
          if(response.status === 204) {
            this.setState({showSuccess: true});
          } else {
            this.setState({showFailure: true});
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({showFailure: true});
        });
    }
    this.setState({loading: false});
  }

  clearErrors() {
    this.setState({
      passwordError: null,
    });
  }

  validate() {
    let passwordError = null;

    // Validate password
    if (!this.state.password) {
      passwordError = 'Please enter a new password';
    }

    if (passwordError) {
      this.setState({
        passwordError,
      });
      return false;
    }
    return true;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, [`${prop}Error`]: null });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  renderPasswordError() {
    if (!!this.state.passwordError) {
      return (
        <FormHelperText id="password-text">{this.state.passwordError}</FormHelperText>
      );
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.showSuccess) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>Password Reset</Typography>
          <Typography className={classes.subHeading} color={'textSecondary'}>You can now login with your new password.</Typography>
        </div>
      );
    }

    if (this.state.showFailure) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>There has been an error resetting your password</Typography>
          <Typography className={classes.subHeading} color={'textSecondary'}>Please contact us for help.</Typography>
        </div>
      );
    }

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h4">Reset Password</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => this.handleSubmit(event)}
            noValidate
          >
            <FormControl
              className={classes.field}
              required={true}
              error={!!this.state.passwordError || !!this.props.passwordError}
              fullWidth
            >
              <InputLabel htmlFor="adornment-password">New Password</InputLabel>
              <Input
                id="adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                value={this.state.password}
                onChange={this.handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {this.renderPasswordError()}
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.loading}
            >
              { this.state.loading ? 'Resetting password...' : 'Reset password' }
            </Button>

          </form>
        </Paper>
      </main>
    );
  }
}

ResetPasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResetPasswordComponent);
