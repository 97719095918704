import Creators from './actions';
import { push } from 'react-router-redux';

import api from '../../../apiService';

const requestProductAction = Creators.requestProduct;
const receiveProductAction = Creators.receiveProduct;
const requestStoreLocationsAction = Creators.requestStoreLocations;
const receiveStoreLocationsAction = Creators.receiveStoreLocations;


const fetchProduct = (productSlug) => {
  return (dispatch) => {
    dispatch(requestProductAction());
    api.get(`/api/v1/product/${productSlug}/`)
      .then(response => {
        dispatch(receiveProductAction(response.data))
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          dispatch(push('/404-not-found'));
        } else if (error.response && error.response.status === 500) {
          dispatch(push('/500-server-error'));
        }
      });
  }
};

const fetchStoreLocations = (id) => {
  return (dispatch) => {
    dispatch(requestStoreLocationsAction());
    api.get(`/api/v1/provider/${id}/`)
      .then(response => {
        dispatch(receiveStoreLocationsAction(response.data))
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          dispatch(push('/404-not-found'));
        } else if (error.response && error.response.status === 500) {
          dispatch(push('/500-server-error'));
        }
      });
  }
};

export default { fetchProduct, fetchStoreLocations }
