import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ContentLoader from 'react-content-loader';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Helmet } from 'react-helmet';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  bgOverlay: {
    background: fade(theme.palette.primary.dark, 0.75),
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bg: {
    backgroundSize: 'cover',
    backgroundPosition: '20% 50%',
    minHeight: 200,
    position: 'relative',
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    background: fade(theme.palette.common.white, 0.75),
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '2.5rem',
    color: theme.palette.common.white
  },
  subHeading: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: theme.palette.common.white
  },
  card: {
    width: 325,
    height: 310,
    margin: theme.spacing.unit * 2,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      background: fade(theme.palette.common.white, 0.75),
    },
  },
  media: {
    height: 0,
    paddingTop: '70%',
  },
  subCatTitle: {
    textDecoration: 'none',
    fontSize: '1.5rem',
  }
});


const PageLoader = props => (
	<ContentLoader
		height={240}
		width={840}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
    <rect x="5" y="20" rx="5" ry="5" width="820" height="10" />
    <rect x="5" y="40" rx="5" ry="5" width="700" height="10" />
    <rect x="5" y="60" rx="5" ry="5" width="515" height="10" />
    <rect x="5" y="80" rx="5" ry="5" width="820" height="10" />
		<circle cx="10" cy="120" r="8" />
		<rect x="25" y="115" rx="5" ry="5" width="220" height="10" />
		<circle cx="10" cy="150" r="8" />
		<rect x="25" y="145" rx="5" ry="5" width="220" height="10" />
		<circle cx="10" cy="180" r="8" />
		<rect x="25" y="175" rx="5" ry="5" width="220" height="10" />
		<circle cx="10" cy="210" r="8" />
		<rect x="25" y="205" rx="5" ry="5" width="220" height="10" />
	</ContentLoader>
)


class PageComponent extends Component {
  constructor(props) {
    super(props);
    // Initially fetch the data
    this.fetchPageData();
  }

  componentDidUpdate(prevProps) {
    // Fetch the data when the url changes
    if (this.props.match.url !== prevProps.match.url) {
      this.fetchPageData();
    }
  }

  fetchPageData() {
    const { pageSlug } = this.props.match.params;
    this.props.fetchPage(pageSlug);
  }

  renderHead() {
    const { page, loading } = this.props;
    if (!loading) {
      return (
        <Helmet>
          <title>{page.page_title} - Chemist Select</title>
          <meta name="description" content={page.meta_description} />
        </Helmet>
      );
    }
  }

  renderBody() {
    const { page, loading } = this.props;
    if (!loading) {
      return (
        <div dangerouslySetInnerHTML={{ __html: page.body }} />
      );
    }
    return (
      <React.Fragment>
        <PageLoader />
        <PageLoader />
      </React.Fragment>
    );
  }

  render() {
    const { page, classes, loading } = this.props;
    return (
      <div>
        {this.renderHead()}
        <div>
          <div className={classes.bg}>
            <div className={classes.bgOverlay} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
              <Typography variant="h1" className={classes.heading}>
                {!loading ? page.title : '...'}
              </Typography>
              <Typography className={classes.subHeading}>
                {!loading ? page.sub_title : '...'}
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <Grid container justify={'center'}>
            <Grid item md={6}>
              <Typography>
              {this.renderBody()}
              </Typography>
            </Grid>
          </Grid>
        </div>

      </div>
    );
  }
}

PageComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PageComponent);
