import ReactGA from 'react-ga';
import Creators from './actions';
import api from "../../../apiService";

const toggleSideMenu = Creators.toggleSideMenu;
const requestProductAutocomplete = Creators.requestProductAutocomplete;
const receiveProductAutocomplete = Creators.receiveProductAutocomplete;


const fetchAutoCompleteProducts = (query) => {
  const autoCompleteThunk = (dispatch) => {
    dispatch(requestProductAutocomplete());

    api.get(`/api/v1/product/?search=${query}&autocomplete=true`)
      .then(response => {
        ReactGA.event({
          category: 'Search',
          action: 'Top bar autocomplete search',
          label: query
        });
        dispatch(receiveProductAutocomplete(response.data.results))
      });
  };

  autoCompleteThunk.meta = {
    debounce: {
      time: 750,
      key: 'PRODUCT_AUTOCOMPLETE'
    }
  };

  return autoCompleteThunk;
};

export default { toggleSideMenu, fetchAutoCompleteProducts, requestProductAutocomplete }
