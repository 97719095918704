import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import api from '../apiService';
import TopBar from './core/TopBar';
import Footer from './core/Footer';
import withRoot from '../withRoot';
import { Home } from './home';
import { Category } from './category';
import { PrimaryCategory } from './primaryCategory';
import { Page } from './page';
import { Product } from './product';
import { Advertising } from "./advertising";
import { NoMatch, SideMenu, ServerError } from './core';
import { SignUp, SignIn, Activate, SignUpConfirmation, ForgotPassword, ResetPassword } from './account';
import { List } from './list';
import ChemistRating from "./rating/ChemistRating";


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  main: {
    backgroundColor: theme.palette.common.white,
  }
});


class App extends Component {

  componentDidMount() {
    api.setup();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>Chemist Select - We Help You Save!</title>
          <meta name="description" content="Welcome to Chemist Select. The best place to find the cheapest chemist prices online."/>
        </Helmet>
        <TopBar />
        <SideMenu />
        <main className={classes.main}>
          <Switch>
            <Route exact path='/' component={Home} />
            
            <Route exact path='/category/prescriptions' component={Category} />
            <Route exact path='/category/:categorySlug' component={PrimaryCategory} />
            <Route exact path='/category/:categorySlug/:lvlTwoCategorySlug' component={Category} />
            <Route exact path='/category/:categorySlug/:lvlTwoCategorySlug/:lvlThreeCategorySlug' component={Category} />
            <Route exact path='/product/:productSlug' component={Product} />
            <Route exact path='/search/:searchTerm' component={Category} />
            
            <Route exact path='/account/sign-up' component={SignUp} />
            <Route exact path='/account/sign-up-confirm' component={SignUpConfirmation} />

            <Route exact path='/account/sign-in' component={SignIn} />
            <Route exact path='/account/activate/:uid/:token' component={Activate} />
            <Route exact path='/account/password/forgot/' component={ForgotPassword} />
            <Route exact path='/account/password/reset/confirm/:uid/:token' component={ResetPassword} />

            <Route exact path='/my-list' component={List} />

            <Route exact path='/chemist-rating/:chemistID' component={ChemistRating} />

            <Route path='/500-server-error' component={ServerError} />
            <Route path='/404-not-found' component={NoMatch} />

            <Route path='/advertising' component={Advertising} />
            <Route exact path='/:pageSlug' component={Page} />

            <Redirect to='/404-not-found' />
          </Switch>
        </main>
        <Footer />
      </div>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(App));
