import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SignInForm from './SignInForm';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});


class SignUpComponent extends Component {
  state = {redirect: '/'};

  componentWillUnmount() {
    this.props.signInClear();
  }

  render() {
    const { signIn, signInError, signInLoading, googleSignIn } = this.props;
    // const { redirect } = this.state;
    return (
      <div>
        <SignInForm
          onSubmit={signIn}
          error={signInError}
          loading={signInLoading}
          googleSignIn={googleSignIn}
        />
      </div>
    );
  }
}

SignUpComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUpComponent);