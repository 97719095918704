import types from './types';

const INITIAL_STATE = {
  product: {},
  loading: true,
  storeLocations: {},
  loadingStoreLocations: true
};

const productReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.REQUEST_PRODUCT:
      return {
        ...state,
        loading: true
      };

    case types.RECEIVE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        loading: false
      };

    case types.REQUEST_STORE_LOCATIONS:
      return {
        ...state,
        loadingStoreLocations: true
      };

    case types.RECEIVE_STORE_LOCATIONS:
      return {
        ...state,
        storeLocations: action.payload,
        loadingStoreLocations: false
      };

    case types.CLEAR_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default productReducer;
