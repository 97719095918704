import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OpenInNew from '@material-ui/icons/OpenInNew';
import LocationOn from '@material-ui/icons/LocationOn';
import ContentLoader from 'react-content-loader';
import { Helmet } from 'react-helmet';
import { Map, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import { geolocated } from 'react-geolocated';
import ReactGA from 'react-ga';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import RoundedButton from '../core/RoundedButton';
import PrescriptionImage from './images/prescription-product-page.jpg';
import WatchlistButton from "../core/WatchlistButton";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  container: {
    padding: theme.spacing.unit * 2,
  },
  innerContainer: {
    padding: theme.spacing.unit,
  },
  responsiveImage: {
    width: '100%',
    height: 'auto',
    maxHeight: 500,
  },
  title: {
    fontWeight: '900',
    fontSize: '2.25rem',
  },
  subTitle: {
    fontWeight: '700',
    fontSize: '1.5rem',
    marginBottom: theme.spacing.unit * 2,
  },
  description: {

  },
  providerTitle: {
    fontWeight: '500',
    fontSize: '1.5rem',
  },
  providerName: {
    fontWeight: '900',
    fontSize: '1.5rem',
    color: theme.palette.secondary.main
  },
  price: {
    fontWeight: '900 !important',
    fontSize: '3rem !important',
  },
  rrpPrice: {
    color: theme.palette.text.secondary,
    textDecoration: 'line-through',
    paddingLeft: 15
  },
  prescriptionPrice: {
    fontWeight: '900 !important',
    fontSize: '2.5rem !important',
  },
  prescriptionPriceLabel: {
    color: theme.palette.text.secondary
  },
  tableRoot: {
    width: '100%',
    overflowX: 'auto',
  },
});


class ProductComponent extends Component {
  constructor(props) {
    super(props);
    this.fetchProduct();
    this.state = {
      mapCenter: [-27.987037, 140.385681],
      mapZoom: 4
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.fetchProduct();
    }
    if (!this.props.loading && prevProps.loading) {
      this.fetchStoreLocations(this.props.product.primary_provider_product.provider.id);
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  static getDerivedStateFromProps(props, state) {
    const { isGeolocationAvailable, isGeolocationEnabled, coords } = props;
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      const newCenter = [props.coords.latitude, props.coords.longitude];
      if (newCenter !== state.mapCenter) {
        return { mapCenter: newCenter, mapZoom: 13 };
      }
    }
    return null;
  }


  fetchProduct() {
    const { productSlug } = this.props.match.params;
    this.props.fetchProduct(productSlug);
  }

  fetchStoreLocations(providerId) {
    this.props.fetchStoreLocations(providerId);
  }

  renderHead() {
    const { product, loading } = this.props;
    if (!loading) {
      return (
        <Helmet>
          <title>Best price {product.title} | Chemist Select</title>
          <meta
            name="description"
            content={`Save on ${product.title} by comparing prices at Chemist Select. Find the best deals from trusted chemists and shop smart for your health needs.`}
          />
        </Helmet>
      );
    }
  }

  renderImage() {
    const { product, loading, classes } = this.props;
    if (!loading) {
      if (product.prescription) {
        return (
          <img
            src={PrescriptionImage}
            alt={product.title}
            className={classes.responsiveImage}
          />
        );
      }
      else {
        return (
          <img
            src={product.primary_provider_product.images[0].image.full}
            alt={product.title}
            className={classes.responsiveImage}
          />
        );
      }
    }

    return (
      <ContentLoader
        height={445}
        width={445}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="5" y="5" rx="5" ry="5" width="435" height="435" />
      </ContentLoader>
    );
  }

  renderTitle() {
    const { product, loading, classes } = this.props;
    if (!loading) {
      return (
        <Typography
          variant={'h1'}
          className={classes.title}
        >
          {`Best price ${product.title}`}
        </Typography>
      );
    }

    return (
      <ContentLoader
        height={400}
        width={685}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="5" y="5" rx="5" ry="5" width="630" height="35" />
        <rect x="5" y="50" rx="5" ry="5" width="180" height="35" />
        <rect x="5" y="100" rx="5" ry="5" width="385" height="30" />
        <rect x="5" y="150" rx="5" ry="5" width="450" height="65" />
        <rect x="10" y="235" rx="20" ry="20" width="250" height="40" />
      </ContentLoader>
    );
  }

  renderProviderTitle() {
    const { product, loading, classes } = this.props;
    if (!loading) {
      return (
        <Typography
          variant={'title'}
          color={'textSecondary'}
          className={classes.providerTitle}
        >
          Cheapest option available at <span className={classes.providerName}>{product.primary_provider_product.provider.title}</span>
        </Typography>
      );
    }
  }

  renderPrices() {
    const { product, loading, classes } = this.props;
    if (!loading) {
      if (product.prescription) {
        return (
          <div>
            <Typography className={classes.prescriptionPrice} component="p" color='primary'>
              <span className={classes.prescriptionPriceLabel}>Private Price</span> ${product.primary_provider_product.current_price.private_price}
            </Typography>
            <Typography className={classes.prescriptionPrice} component="p" color='primary'>
              <span className={classes.prescriptionPriceLabel}>Discounted PBS Price</span> ${product.primary_provider_product.current_price.discounted_price}
            </Typography>
            <Typography className={classes.prescriptionPrice} component="p" color='primary'>
              <span className={classes.prescriptionPriceLabel}>Concession PBS Price</span> ${product.primary_provider_product.current_price.concession_price}
            </Typography>
            <Typography className={classes.prescriptionPrice} component="p" color='primary'>
              <span className={classes.prescriptionPriceLabel}>Safety Net Price</span> ${product.primary_provider_product.current_price.safety_net_price}
            </Typography>
            {product.primary_provider_product.current_price.label !== null &&
              <Typography component="p" color='textSecondary'>
                {product.primary_provider_product.current_price.label}
              </Typography>
            }
          </div>
        );
      }
      else {
        return (
          <div>
            <Typography className={classes.price} component="p" color='primary'>
              ${product.primary_provider_product.current_price.price}
              {product.primary_provider_product.current_price.price !== product.primary_provider_product.current_price.rrp ?
                <span className={classes.rrpPrice}>${product.primary_provider_product.current_price.rrp}</span>
                :
                null
              }
            </Typography>
            {product.primary_provider_product.current_price.label !== null &&
              <Typography component="p" color='textSecondary'>
                {product.primary_provider_product.current_price.label}
              </Typography>
            }
          </div>
        );
      }
    }
  }

  renderExitButton() {
    const { product, loading } = this.props;
    if (!loading) {
      return (
        <RoundedButton
          variant={'contained'}
          size={'large'}
          color={'primary'}
          rel='noreferrer'
          target='_blank'
          href={product.primary_provider_product.url}
          onClick={() => this.handleOutClickTracking(product.primary_provider_product.provider.title, product.primary_provider_product.url)}
        >
          View on {product.primary_provider_product.provider.title} website
        </RoundedButton>
      );
    }
  }

  handleOutClickTracking(chemist, url) {
    ReactGA.event({
      category: 'Outbound Link',
      action: chemist,
      label: url
    });
  }

  renderAlternateList() {
    const { product, loading, classes } = this.props;
    if (!loading && product.provider_products.length > 1) {
      if (product.prescription) {
        return (
          <div>
            <Typography
              variant={'h6'}
              className={classes.subTitle}
            >
              Also available at
            </Typography>
            <Divider light style={{marginBottom: 12}}/>
            <div className={classes.tableRoot}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Chemist</TableCell>
                    <TableCell numeric>Private</TableCell>
                    <TableCell numeric>Discounted PBS</TableCell>
                    <TableCell numeric>Concession PBS</TableCell>
                    <TableCell numeric>Safety Net</TableCell>
                    <TableCell>Map</TableCell>
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.provider_products.map(prod => {
                    return (
                      <TableRow key={`${prod.title}-${prod.provider.title}`}>
                        <TableCell component="th" scope="row">
                          {prod.provider.title}
                        </TableCell>
                        <TableCell numeric>{prod.current_price.private_price}</TableCell>
                        <TableCell numeric>{prod.current_price.discounted_price}</TableCell>
                        <TableCell numeric>{prod.current_price.concession_price}</TableCell>
                        <TableCell numeric>{prod.current_price.safety_net_price}</TableCell>
                        <TableCell>
                          <RoundedButton
                            variant={'contained'}
                            color={'default'}
                            onClick={() => this.fetchStoreLocations(prod.provider.id)}
                          >
                            <LocationOn />
                          </RoundedButton>
                        </TableCell>
                        <TableCell>
                          <RoundedButton
                            variant={'contained'}
                            color={'default'}
                            target='_blank'
                            rel='noreferrer'
                            href={prod.url}
                            onClick={() => this.handleOutClickTracking(prod.provider.title, prod.url)}
                          >
                            <OpenInNew />
                          </RoundedButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </div>
        );
      }
      else {
        return (
          <div>
            <Typography
              variant={'h6'}
              className={classes.subTitle}
            >
              Available at
            </Typography>
            <Divider light style={{marginBottom: 12}}/>
            <div>
              <List>
                {product.provider_products.map(prod => (
                  <ListItem key={`${prod.title}-${prod.provider.title}`}>
                    <ListItemText
                      primary={`${prod.provider.title} - $${prod.current_price.price}`}
                      secondary={prod.title}
                    />
                    <ListItemSecondaryAction>
                      <RoundedButton
                        variant={'contained'}
                        color={'default'}
                        onClick={() => this.fetchStoreLocations(prod.provider.id)}
                      >
                        <LocationOn />
                      </RoundedButton>
                      <RoundedButton
                        variant={'contained'}
                        color={'default'}
                        target='_blank'
                        rel='noreferrer'
                        href={prod.url}
                        onClick={() => this.handleOutClickTracking(prod.provider.title, prod.url)}
                      >
                        <OpenInNew />
                      </RoundedButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        );
      }
    }
  }

  renderMarkersForProvider() {
    const { loadingStoreLocations, storeLocations } = this.props;
    if (!loadingStoreLocations) {
      return (
        <MarkerClusterGroup>
          {storeLocations.locations.map(loc => {
            return (
              <Marker key={`${storeLocations.title}-${loc.title}`} position={[loc.latitude, loc.longitude]}>
                <Popup>
                  {`${storeLocations.title} - ${loc.title}`}<br/>
                  {loc.address}<br/>
                  {`${loc.suburb}, ${loc.state}, ${loc.postcode}`}
                </Popup>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      );
    }
  }

  renderWatchlistButton() {
    const { product, signedIn } = this.props;
    if (product.id) {
      return (
        <WatchlistButton signedIn={signedIn} productId={product.id} itemId={product.watched} size={'large'} from={'Product page'} rounded/>
      );
    }
  }

  renderMapTitle() {
    const { loadingStoreLocations, storeLocations } = this.props;
    if (!loadingStoreLocations) {
      if (storeLocations.locations.length === 0) {
        return (
        `${storeLocations.title} is only available online`
      );
      }
      return (
        `Nearby ${storeLocations.title} Stores`
      );
    }
    return (
      'Nearby Stores...'
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {this.renderHead()}
        <Grid container justify={'center'} className={classes.container}>
          <Grid item md={8} xs={12}>
            <Grid container>

              <Grid item md={4} xs={12} className={classes.innerContainer}>
                {this.renderImage()}
              </Grid>

              <Grid item md={8} xs={12} className={classes.innerContainer}>
                <Grid container spacing={8}>

                  <Grid item xs={12}>
                    {this.renderTitle()}
                  </Grid>

                  <Grid item xs={12}>
                    {this.renderProviderTitle()}
                  </Grid>

                  <Grid item xs={12}>
                    {this.renderPrices()}
                  </Grid>

                  <Grid item xs={12}>
                    {this.renderExitButton()}
                  </Grid>

                  <Grid item xs={12}>
                    {this.renderWatchlistButton()}
                  </Grid>

                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid container justify={'center'} className={classes.container}>
          <Grid item md={8} xs={12} className={classes.innerContainer}>
            <Grid container spacing={8}>
              <Grid item xs={12}>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  variant={'h6'}
                  className={classes.subTitle}
                >
                  {this.renderMapTitle()}
                </Typography>

                <Divider light style={{ marginBottom: 12 }}/>

                <Map center={this.state.mapCenter} zoom={this.state.mapZoom} style={{height: 400}} maxZoom={18}>
                  <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  {this.renderMarkersForProvider()}
                </Map>
              </Grid>

              <Grid item xs={12} md={6}>
                {this.renderAlternateList()}
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ProductComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 7000,
})(withStyles(styles)(ProductComponent));
