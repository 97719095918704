import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import store from "../../Store";
import { push } from "react-router-redux";
import Dotdotdot from 'react-dotdotdot';
import ContentLoader from 'react-content-loader';
import URL from 'url-parse';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import RoundedButton from '../core/RoundedButton';
import PrescriptionImage from './images/prescription-category.jpg';
import WatchlistButton from '../core/WatchlistButton';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  bgOverlay: {
    background: fade(theme.palette.primary.dark, 0.75),
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  bg: {
    backgroundSize: 'cover',
    backgroundPosition: '20% 50%',
    minHeight: 200,
    position: 'relative',
    padding: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '2.5rem',
    color: theme.palette.common.white
  },
  subHeading: {
    textAlign: 'center',
    fontSize: '1.2rem',
    color: theme.palette.common.white
  },
  card: {
    width: 320,
    height: 400,
    padding: theme.spacing.unit,
    margin: theme.spacing.unit,
    position: 'relative'
  },
  sidebarCard: {
    padding: theme.spacing.unit,
    margin: theme.spacing.unit,
  },
  sidebarHeader: {
    padding: theme.spacing.unit * 2
  },
  media: {
    height: 0,
    paddingTop: '70%',
  },
  price: {
    fontWeight: '900 !important',
    fontSize: '1.5rem !important',
  },
  prescriptionPrice: {
    fontWeight: '600 !important',
    fontSize: '1.25rem !important',
    lineHeight: '1.25rem !important',
  },
  rrpPrice: {
    color: theme.palette.text.secondary,
    textDecoration: 'line-through',
    paddingLeft: 10
  },
  rightPrescriptionPrice: {
    paddingLeft: 10,
  },
  imgStyle: {
    backgroundSize: 'auto !important',
  },
  resultsText: {
    paddingTop: 10,
  },
  sortContainer: {
    paddingBottom: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 4,
  },
  iconSmall: {
    fontSize: 20,
    marginLeft: theme.spacing.unit,
  },
});

const ProductLoader = props => (
	<ContentLoader
		height={400}
		width={320}
		speed={2}
		primaryColor="#f3f3f3"
		secondaryColor="#ecebeb"
		{...props}
	>
		<rect x="5" y="5" rx="5" ry="5" width="310" height="215" />
		<rect x="30" y="235" rx="5" ry="5" width="240" height="15" />
		<rect x="30" y="265" rx="5" ry="5" width="260" height="15" />
		<rect x="30" y="295" rx="5" ry="5" width="225" height="25" />
		<rect x="30" y="350" rx="5" ry="5" width="100" height="35" />
    <rect x="140" y="350" rx="5" ry="5" width="100" height="35" />
	</ContentLoader>
);

class CategoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: 'title',
    };
    this.fetchResults();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.fetchResults();
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  fetchResults(fetchCategory=true) {
    const { categorySlug, lvlTwoCategorySlug, lvlThreeCategorySlug, searchTerm } = this.props.match.params;
    const { sort } = this.state;
    if (searchTerm) {
      this.props.clearData();
      this.props.fetchProducts(null, searchTerm, null, sort);
    } else {
      let slug = null;
      if (this.props.match.url === '/category/prescriptions') {
        slug = 'prescriptions';
      }
      else if (lvlThreeCategorySlug) {
        slug = `${categorySlug}-${lvlTwoCategorySlug}-${lvlThreeCategorySlug}`;
      }
      else {
        slug = `${categorySlug}-${lvlTwoCategorySlug}`;
      }
      if (fetchCategory) {
        this.props.fetchCategory(slug);
        // this.props.clearData();
      }
      this.props.clearProducts();
      this.props.fetchProducts(slug, null, null, sort);
    }
  }

  renderNoOfResults() {
    const { classes, count } = this.props;
    if (count == null) {
      return (
        <Typography gutterBottom component="p" align='center' className={classes.resultsText}>
          ... products match your search criteria.
        </Typography>
      );
    } else {
      return (
        <Typography gutterBottom component="p" align='center' className={classes.resultsText}>
          {count} products match your search criteria.
        </Typography>
      );
    }
  }

  renderCardMedia(product) {
    const { classes } = this.props;
    if (product.prescription) {
      return (
        <CardMedia
          className={classes.media}
          image={PrescriptionImage}
          title={product.title}
        />
      );
    }
    else if (product.primary_provider_product.images.length !== 0) {
      return (
        <CardMedia
          className={classes.media}
          image={product.primary_provider_product.images[0].image.category}
          title={product.title}
          classes={{root: classes.imgStyle}}
        />
      );
    }
    else {
      return (
        <CardMedia
          className={classes.media}
          // image="https://via.placeholder.com/325x230?text=ChemistSelect.com.au"
          title={product.title}
        />
      );
    }
  }

  renderPrice(product) {
    const { classes } = this.props;

    const privatePrice = product.primary_provider_product.current_price.private_price;
    const discountedPrice = product.primary_provider_product.current_price.discounted_price;
    const concessionPrice = product.primary_provider_product.current_price.concession_price;
    const safetyNetPrice = product.primary_provider_product.current_price.safety_net_price;
    const price = product.primary_provider_product.current_price.price;
    const rrp = product.primary_provider_product.current_price.rrp;


    if (product.prescription) {
      return (
        <div>
          <Typography className={classes.prescriptionPrice} component="p" color='primary'>
            <Tooltip title={'Private Price'} placement="top">
              <span>P:${privatePrice}</span>
            </Tooltip>
            <Tooltip title={'PBS Price'} placement="top">
              <span className={classes.rightPrescriptionPrice}>PBS:${discountedPrice}</span>
            </Tooltip>
          </Typography>
          <Typography className={classes.prescriptionPrice} component="p" color='primary'>
            <Tooltip title={'Concession Price'} placement="top">
              <span>CON:${concessionPrice}</span>
            </Tooltip>
            <Tooltip title={'Safety Net Price'} placement="top">
              <span className={classes.rightPrescriptionPrice}>SN:${safetyNetPrice}</span>
            </Tooltip>
          </Typography>
        </div>
      );
    }
    else {
      return (
        <Typography className={classes.price} component="p" color='primary'>
          ${price}
          {price !== rrp ?
            <span className={classes.rrpPrice}>${rrp}</span>
            :
            null
          }
        </Typography>
      );
    }

  }

  renderOptionsCount(product) {
    let count = 0;
    count = product.provider_products.length;
    if (count > 1) return ` +${count - 1} more`;
  }

  renderProducts() {
    const { products, classes, category, signedIn } = this.props;
    const arr = Array.from(Array(48).keys());
    if(products.length > 0) {
      return (
        <Grid item md={category && category.children.length > 0 ? 10 : 12} xs={12}>
          <Grid container justify={'center'}>
            {products.map((product) => {
              return (
                <Grid item key={product.slug}>
                  <Card className={classes.card}>
                    {this.renderCardMedia(product)}
                    <CardContent>
                      <Tooltip title={product.title} placement="top">
                        <Typography gutterBottom component="p">
                          <Dotdotdot clamp={1}>
                            {product.title}
                          </Dotdotdot>
                        </Typography>
                      </Tooltip>
                      <Typography gutterBottom component="p" color={'secondary'}>
                        {product.primary_provider_product.provider.title}
                        {this.renderOptionsCount(product)}
                      </Typography>
                      {this.renderPrice(product)}
                    </CardContent>

                    <CardActions>
                      <RoundedButton
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => store.dispatch(push(`/product/${product.slug}`))}
                      >
                        Compare
                        <CompareArrowsIcon className={classes.iconSmall}/>
                      </RoundedButton>
                      <WatchlistButton signedIn={signedIn} productId={product.id} itemId={product.watched} from={'Category page'} />
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid item md={category && category.children.length > 0 ? 10 : 12} xs={12}>
          <Grid container justify={'center'}>
            {arr.map((i) => {
              return(
                <Grid item key={i}>
                  <Card className={classes.card}>
                    <ProductLoader />
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      );
    }
  }

  loadMoreProducts() {
    const url = new URL(this.props.next);
    ReactGA.event({
      category: 'Results',
      action: 'Load more',
    });
    this.props.fetchProducts(null, null, null, null, `${url.pathname}${url.query}`);
  }

  renderHead() {
    const { category } = this.props;
    if (category) {
      return (
        <Helmet>
          <title>Compare cheapest {category.title} products | Chemist Select</title>
          <meta
            name="description"
            content={`Save on ${category.title} products by comparing prices at Chemist Select. Find the best deals from trusted chemists and shop smart for your health needs.`} />
        </Helmet>
      );
    }
  }

  renderLoadMore() {
    const { next, loading, category } = this.props;
    if (loading) {
      const { classes } = this.props;
      const arr = Array.from(Array(12).keys());
      return (
        <Grid container justify={'center'}>
          {category && category.children.length > 0 ?
            <Grid item md={2}/> : null
          }
          <Grid item md={category && category.children.length > 0 ? 10 : 12}>
            <Grid container justify={'center'}>
            {arr.map((i) => {
              return(
                <Grid item key={i}>
                  <Card className={classes.card}>
                    <ProductLoader />
                  </Card>
                </Grid>
              );
            })}
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (!loading && !!next) {
      return (
        <Grid container justify={'center'}>
          <Grid item>
            <RoundedButton variant="contained" color={'primary'} onClick={this.loadMoreProducts.bind(this)}>Load More</RoundedButton>
          </Grid>
        </Grid>
      );
    }
  }

  renderSideBar() {
    const { category, classes } = this.props;
    if (category && category.children.length > 0) {
      return (
        <Grid item md={2} xs={12}>
          <Card className={classes.sidebarCard}>
            <div className={classes.sidebarHeader}>
              <Typography variant={'h3'}>Refine</Typography>
            </div>
            <Divider/>
            <List>
              {category.children.map(child => (
                <ListItem key={child.slug} button>
                  <ListItemText primary={child.title} onClick={() => {
                    this.props.clearData();
                    store.dispatch(push(child.uri));
                  }}/>
                </ListItem>
              ))}
            </List>
          </Card>
        </Grid>
      );
    }
  }

  renderTitle() {
    const { category, match } = this.props;
    const { searchTerm } = match.params;
    if (category) {
      return `Compare cheapest ${category.title} product prices`;
    } else if (searchTerm) {
      return `Search Results: ${searchTerm}`;
    } else {
      return '...';
    }
  }

  renderSubTitle() {
    const { category } = this.props;
    if (category) {
      return category.description;
    }
  }

  handleSortChange(event) {
    ReactGA.event({
      category: 'Results',
      action: 'Sort',
      label: event.target.value,
    });
    this.setState(
      { sort: event.target.value },
      () => this.fetchResults(false)
    );
  }

  renderOrderDropDown() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item className={classes.sortContainer}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="sort">Sort</InputLabel>
                <Select
                  value={this.state.sort}
                  onChange={(event) => this.handleSortChange(event)}
                  inputProps={{
                    name: 'sort',
                    id: 'sort',
                  }}
                >
                  <MenuItem value={'percentage_saving'}>Discount</MenuItem>
                  <MenuItem value={'title'}>Name - A to Z</MenuItem>
                  <MenuItem value={'-title'}>Name - Z to A</MenuItem>
                  <MenuItem value={'price'}>Price - Low to High</MenuItem>
                  <MenuItem value={'-price'}>Price - High to Low</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { category, classes } = this.props;
    return (
      <div>
        {this.renderHead()}
        <div>
          <div className={classes.bg} style={{
            backgroundImage: `url(${category && category.image ? category.image.banner : null})`,
            }}
          >
            <div className={classes.bgOverlay} />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 1 }}>
              <Typography variant="h1" className={classes.heading}>
                {this.renderTitle()}
              </Typography>
              <Typography className={classes.subHeading}>
                {this.renderSubTitle()}
              </Typography>
            </div>
          </div>
        </div>

        <Grid container justify={'center'}>
          <Grid item md={12}>
            {this.renderNoOfResults()}
          </Grid>
        </Grid>


        {this.renderOrderDropDown()}

        <Grid container >
          {this.renderSideBar()}
          {this.renderProducts()}
        </Grid>
        {this.renderLoadMore()}

      </div>
    );
  }
}

CategoryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CategoryComponent);
