import types from './types.js';

const requestProducts = () => {
  return({
    type: types.REQUEST_PRODUCTS,
  });
};

const receiveProducts = (products) => {
  return({
    type: types.RECEIVE_PRODUCTS,
    payload: products
  });
};

const clearData = () => {
  return({
    type: types.CLEAR_DATA,
  });
};

const clearProducts = () => {
  return({
    type: types.CLEAR_PRODUCTS,
  });
};

const requestCategory = () => {
  return({
    type: types.REQUEST_CATEGORY
  });
};

const receiveCategory = (category) => {
  return({
    type: types.RECEIVE_CATEGORY,
    payload: category
  });
};

export default {
  requestProducts,
  receiveProducts,
  clearData,
  clearProducts,
  requestCategory,
  receiveCategory,
}
