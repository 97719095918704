import axios from 'axios';
import Creators from './actions';
import { push } from 'react-router-redux';

const requestPageAction = Creators.requestPage;
const receivePageAction = Creators.receivePage;

const fetchPage = (pageSlug) => {
  return (dispatch) => {
    dispatch(requestPageAction());
    axios.get(`/api/v1/page/${pageSlug}/`)
      .then(response => {
        dispatch(receivePageAction(response.data))
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          dispatch(push('/404-not-found'));
        } else if (error.response && error.response.status === 500) {
          dispatch(push('/500-server-error'));
        }

      });
  }
};

export default { fetchPage }
