import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import AppSearch from './AppSearchContainer';
import chemistSelectLogo from './images/chemist-select-logo-purple-border.png';
import PropTypes from "prop-types";
import { coreOperations } from './duck';
import { accountOperations } from '../account/duck';
import RoundedButton from './RoundedButton';
import { categoryList } from "../home/HomeData";
import store from "../../Store";
import { push } from "react-router-redux";


const styles = theme => ({
  // root: {
  //   flexGrow: 1,
  // },
  flex: {
    flex: 1,
  },
  logo: {
    width: 140,
    alignSelf: 'center',
  },
  menuButton: {},
  logoWrapper: {
    flex: 1,
  },
  buttonGreyText: {
    color: theme.palette.grey['600']
  },
  hideSmall: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  typography: {
    margin: theme.spacing.unit * 2,
  },
  link: {
    textDecoration: 'none',
    margin: theme.spacing.unit * 2,
    color: theme.palette.text.primary
  },
  accountCircle: {
    fontSize: 36,
  }
});


class TopBar extends Component {
  state = { anchorEl: null, accountAnchorEl: null };

  handleClick = event => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Top bar category button click'
    });
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAccountClick = event => {
    this.setState({ accountAnchorEl: event.currentTarget });
  };

  handleAccountClose = () => {
    this.setState({ accountAnchorEl: null });
  };

  handleSignOut = () => {
    this.props.signOut();
    this.handleAccountClose();
  };

  handleDrawerOpenClick() {
    const { sideMenuOpen, toggleSideMenu } = this.props;
    ReactGA.event({
      category: 'Navigation',
      action: 'Drawer toggle',
      label: sideMenuOpen ? 'Close' : 'Open',
    });
    toggleSideMenu(!sideMenuOpen)
  }

  handleSignInClick() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Top bar sign in click',
    });
    store.dispatch(push('/account/sign-in'));
  }

  handleSignUpClick() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Top bar sign up click',
    });
    store.dispatch(push('/account/sign-up'));
  }

  renderAccountButtons() {
    const { signedIn, classes } = this.props;
    const { accountAnchorEl } = this.state;
    const open = Boolean(accountAnchorEl);

    if (signedIn) {
      return (
        <span>

          <RoundedButton
            variant='contained'
            color={'secondary'}
            onClick={() => store.dispatch(push('/my-list'))}
          >
            My List
          </RoundedButton>

          <IconButton
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={this.handleAccountClick}
            color="inherit"
          >
            <AccountCircle
              className={classes.accountCircle}
              color={'primary'}
            />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={accountAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleAccountClose}
          >

            {/*<MenuItem onClick={this.handleAccountClose}>My Account</MenuItem>*/}
            <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
          </Menu>
        </span>
      );
    } else {
      return (
        <span>
          <RoundedButton
            otherClasses={{
              root: classes.buttonGreyText,
            }}
            onClick={() => this.handleSignInClick()}
          >
            Login
          </RoundedButton>
          <RoundedButton
            variant="contained"
            color={'secondary'}
            onClick={() => this.handleSignUpClick()}
          >
            Sign Up
          </RoundedButton>
        </span>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;

    return(
      <AppBar position="sticky" color={"inherit"}>
        <Toolbar>

          <IconButton
            className={classes.menuButton}
            aria-label="Menu"
            onClick={() => this.handleDrawerOpenClick()}
          >
            <MenuIcon />
          </IconButton>

          <Link to={"/"} style={{ textDecoration: 'none', alignItems: 'center', display: 'flex', padding: 10 }}>
            <img src={chemistSelectLogo} className={classes.logo} alt='Chemist Select Logo' />
          </Link>

          <AppSearch />

          <div className={classes.flex} />
          <div className={classes.hideSmall}>
            <RoundedButton variant="contained" color={'primary'} onClick={this.handleClick}>Categories</RoundedButton>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {categoryList.map(cat => (
                <Link key={cat.id} to={cat.linkURI} style={{ textDecoration: 'none'}} onClick={() => this.handleClose()}>
                  <Typography className={classes.link} id={cat.id}>{cat.title}</Typography>
                </Link>
                ))}
            </Popover>

            {/*<RoundedButton*/}
              {/*otherClasses={{*/}
                {/*root: classes.buttonGreyText,*/}
              {/*}}*/}
            {/*>*/}
              {/*Previous List*/}
            {/*</RoundedButton>*/}
            {this.renderAccountButtons()}
          </div>

        </Toolbar>
      </AppBar>
    );
  }

}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { sideMenuOpen } = state.core;
  const { user, signedIn } = state.account;

  return { sideMenuOpen, user, signedIn };
};

export default connect(mapStateToProps, {
  toggleSideMenu: coreOperations.toggleSideMenu,
  signOut: accountOperations.signOut
})(withStyles(styles)(withWidth()(TopBar)));
