import { connect } from 'react-redux';
import SignInComponent from './SignInComponent';
import { accountOperations } from '../../duck';
import Creators from '../../duck/actions';

const mapStateToProps = state => {
  const { signInLoading, signInError } = state.account;
  return { signInLoading, signInError }
};

const SignInContainer = connect(
  mapStateToProps,
  {
    signInClear: Creators.signInClear,
    signIn: accountOperations.signIn,
    googleSignIn: accountOperations.googleSignIn,
  }
)(SignInComponent);

export default SignInContainer;
