import types from './types.js';

const requestPage = () => {
  return({
    type: types.REQUEST_PAGE
  });
};

const receivePage = (page) => {
  return({
    type: types.RECEIVE_PAGE,
    payload: page
  });
};

export default {
  requestPage,
  receivePage,
}
