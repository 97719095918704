import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { SocialIcon } from 'react-social-icons';
import ReactGA from 'react-ga';

import RoundedButton from './RoundedButton';
import chemistSelectLogo from './images/chemist-select-logo-purple-border.png';
import appStore from './images/apple-badge.svg';
import playStore from './images/google-play-badge.png';
import erxLogo from './images/erx-logo.png';
import digitalHealthLogo from './images/aus-digital-health-agency-logo.png';
import store from "../../Store";
import { categoryList } from "../home/HomeData";
import {push} from "react-router-redux";
import ListItem from "./SideMenuComponent";

const styles = theme => ({
  // root: {
  //   flexGrow: 1,
  // },
  flex: {
    flex: 1,
  },
  logo: {
    width: 140,
    alignSelf: 'center',
  },
  footerContainer: {
    background: theme.palette.grey['800'],
    // height: 400
  },
  footerContainerInner: {
    padding: theme.spacing.unit * 5,
    borderBottom: '1px solid white',
  },
  footerLogoContainer: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerLowerContainer: {
    margin: theme.spacing.unit * 4,

  },
  footerText: {
    color: theme.palette.common.white
  },
  footerFollowText: {
    color: theme.palette.common.white
  },
  footerIconLink: {
    padding: theme.spacing.unit,
    color: theme.palette.common.white
  },
  footerTitle: {
    color: theme.palette.common.white,
    fontSize: '1.2rem',
    fontWeight: '900',
  },
  footerTopLeft: {
    background: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 4,
  },
  footerTopRight: {
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.unit * 4,
  },
  footerLink: {
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
  footerHorizontalLink: {
    padding: theme.spacing.unit / 2
  },
  followContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },
  lowerLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    }
  },
  copyrightText: {
    color: theme.palette.common.white,
    padding: theme.spacing.unit * 2,
  },
  fastCompareTitle: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    fontWeight: 900
  },
  fastCompareSubTitle: {
    color: theme.palette.common.white,
    fontSize: '1.5rem',
    fontWeight: 600
  },
  roundedInput: {
    borderRadius: 25,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey['200']}`,
    padding: '14px 16px',
  },
  submitButtonContainer: {
    position: 'absolute',
    top: -1,
    right: -2
  },
});


class Footer extends Component {
  state = {searchTerm: ''};

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.searchTerm) {
      ReactGA.event({
        category: 'Search',
        action: 'Footer search',
        label: this.state.searchTerm
      });
      store.dispatch(push(`/search/${this.state.searchTerm}`));
    }
  }

  handleFooterLinkClick(title) {
    ReactGA.event({
      category: 'Navigation',
      action: 'Footer link click',
      label: title
    });
  }

  render() {
    const { classes } = this.props;

    return(
      <footer className={classes.footerContainer}>
        <Grid container>
          <Grid item md={6} xs={12} className={classes.footerTopLeft}>
            <Typography variant={'h6'} gutterBottom className={classes.fastCompareTitle}>Fast Compare</Typography>
          </Grid>
          <Grid item md={6} xs={12} className={classes.footerTopRight}>
            <form onSubmit={(event) => this.handleSubmit(event)}>
              <FormControl fullWidth={true}>
                <div style={{ position: 'relative', width: 315}}>
                  <TextField
                    placeholder={'Search by product or ailment'}
                    label=''
                    fullWidth={true}
                    value={this.state.searchTerm}
                    onChange={(event) => this.setState({searchTerm: event.target.value})}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.roundedInput,
                      },
                    }}
                  />
                  <div className={classes.submitButtonContainer}>
                    <RoundedButton
                      type='submit'
                      variant="contained"
                      color="secondary"
                    >
                      Search
                    </RoundedButton>
                  </div>
                </div>
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <Grid container justify={"center"}>
          <Grid item md={10} className={classes.footerContainerInner}>
            <Grid container spacing={16}>
              <Grid item md={3} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <a href={"https://apps.apple.com/us/app/chemist-select/id1496941787"}>
                  <img src={appStore} height={60} alt='Get on App Store' style={{paddingLeft: 14}}/>
                </a>
                <a href={"https://play.google.com/store/apps/details?id=au.com.chemistselect.chemistselect"}>
                  <img src={playStore} height={98} alt='Get on Play Store' style={{paddingTop: 10}} />
                </a>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography className={classes.footerTitle}>
                  CATEGORIES
                </Typography>
                <div className={classes.footerText}>
                  <ul style={{listStyle: 'none', paddingLeft: 0}}>
                    {categoryList.map(cat => (
                      <li key={cat.id}><Link to={cat.linkURI} className={classes.footerLink}  onClick={() => this.handleFooterLinkClick(cat.title)}>{cat.title}</Link></li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <Typography className={classes.footerTitle}>
                  CHEMIST SELECT
                </Typography>
                <div className={classes.footerText}>
                  <ul style={{listStyle: 'none', paddingLeft: 0}}>
                    <li><Link to={"/about"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('About')}>About</Link></li>
                    <li><Link to={"/my-list"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Watch List')}>WatchList</Link></li>
                    <li><Link to={"/about-shopping-lists"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Shopping Lists')}>ShoppingLists</Link></li>
                    <li><Link to={"/"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('MyScripts')}>MyScripts</Link></li>
                    <li><Link to={"/"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('MyServices')}>MyServices</Link></li>
                    <li><Link to={"/faqs"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('FAQs')}>FAQs</Link></li>
                    <li><Link to={"/privacy-policy"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Privacy')}>Privacy</Link></li>
                    <li><Link to={"/terms-and-conditions"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Terms & Conditions')}>Terms & Conditions</Link></li>
                    <li><Link to={"/account/sign-in"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Sign In')}>Login</Link></li>
                    <li><Link to={"/account/sign-up"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Free Sign Up')}>Sign Up</Link></li>
                    <li><Link to={"/contact-us"} className={classes.footerLink} onClick={() => this.handleFooterLinkClick('Contact Us')}>Contact Us</Link></li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={3} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                <span><img src={erxLogo} height={70} alt='eRX'/></span>
                <span><img src={digitalHealthLogo} height={70} alt='Digital health' style={{paddingTop: 12}} /></span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justify={"center"}>
          <Grid item md={10} className={classes.footerLowerContainer}>
            <Grid container spacing={16}>
              <Grid item md={8} xs={12} className={classes.lowerLinkContainer}>
              </Grid>
              <Grid item md={4} xs={12} className={classes.followContainer}>
                <div className={classes.footerHorizontalLink}>
                  <SocialIcon url={"https://www.tiktok.com/@chemistselect"} style={{width: 45, height: 45}} />
                </div>
                <div className={classes.footerHorizontalLink}>
                  <SocialIcon url={"https://twitter.com/ChemistSelect1"} style={{width: 45, height: 45}}/>
                </div>
                <div className={classes.footerHorizontalLink}>
                  <SocialIcon url={"https://www.instagram.com/chemistselect/"} style={{width: 45, height: 45}}/>
                </div>
                <div className={classes.footerHorizontalLink}>
                  <SocialIcon url={"https://www.linkedin.com/company/chemist-select/"} style={{width: 45, height: 45}}/>
                </div>
                <div className={classes.footerHorizontalLink}>
                  <SocialIcon url={"https://www.facebook.com/chemistselect.39566"} style={{width: 45, height: 45}}/>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justify={"center"}>
          <Grid item md={10} className={classes.footerLogoContainer}>
            <Link to={"/"} style={{ textDecoration: 'none', alignItems: 'center', display: 'flex'}}>
              <img src={chemistSelectLogo} className={classes.logo} alt='Chemist Select Logo' />
            </Link>
            <Typography className={classes.copyrightText}>
              Copyright {new Date().getFullYear()} CHEMIST SELECT Pty Ltd ABN: 80625645383 Trading as "Chemist Select Pty Ltd"
            </Typography>
          </Grid>
        </Grid>
      </footer>
    );
  }

}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
