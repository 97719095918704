import axios from 'axios';
import Creators from './actions';
import { push } from 'react-router-redux';

const requestCategoryAction = Creators.requestCategory;
const receiveCategoryAction = Creators.receiveCategory;

const fetchCategory = (categorySlug) => {
  return (dispatch) => {
    dispatch(requestCategoryAction());
    axios.get(`/api/v1/category/${categorySlug}/`)
      .then(response => {
        dispatch(receiveCategoryAction(response.data))
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          // console.log('error', error);
          dispatch(push('/404-not-found'));
        } else if (error.response && error.response.status === 500) {
          dispatch(push('/500-server-error'));
        }

      });
  }
};

export default { fetchCategory }
