import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '8rem',
    paddingTop: theme.spacing.unit * 8,
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '3rem',
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  }
});

class ServerError extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.heading} color={'textSecondary'}>500</Typography>
        <Typography className={classes.subHeading} color={'textSecondary'}>
          Sorry, there has been a server error, our engineers have been notified and will be investigating.
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(ServerError);
