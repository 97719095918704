import { connect } from 'react-redux';
import SideMenuComponent from './SideMenuComponent';
import { coreOperations } from './duck';

const mapStateToProps = state => {
  const { sideMenuOpen } = state.core;
  const { signedIn } = state.account;
  return { sideMenuOpen, signedIn }
};

const SideMenuContainer = connect(
  mapStateToProps,
  { toggleSideMenu: coreOperations.toggleSideMenu, }
)(SideMenuComponent);

export default SideMenuContainer;
