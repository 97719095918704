import types from './types.js';

const requestCategory = () => {
  return({
    type: types.REQUEST_CATEGORY
  });
};

const receiveCategory = (category) => {
  return({
    type: types.RECEIVE_CATEGORY,
    payload: category
  });
};

export default {
  requestCategory,
  receiveCategory,
}
