import types from './types.js';


const signUpRequest = () => {
  return ({
    type: types.SIGN_UP_REQUEST,
  });
};

const signUpSuccess = () => {
  return ({
    type: types.SIGN_UP_SUCCESS
  });
};

const signUpError = (errors) => {
  return ({
    type: types.SIGN_UP_ERROR,
    payload: errors
  });
};

const signUpClear = () => {
  return ({
    type: types.SIGN_UP_CLEAR,
  });
};

const signInRequest = () => {
  return ({
    type: types.SIGN_IN_REQUEST,
  });
};

const signInSuccess = (user) => {
  return ({
    type: types.SIGN_IN_SUCCESS,
    payload: user
  });
};

const signInError = (errors) => {
  return ({
    type: types.SIGN_IN_ERROR,
    payload: errors
  });
};

const signInClear = () => {
  return ({
    type: types.SIGN_IN_CLEAR,
  });
};

const signOut = () => {
  return ({
    type: types.SIGN_OUT,
  });
};

export default {
  signUpRequest,
  signUpSuccess,
  signUpError,
  signUpClear,
  signInRequest,
  signInSuccess,
  signInError,
  signInClear,
  signOut,
}
