import { connect } from 'react-redux';
import ListComponent from './ListComponent';
import { listOperations } from './duck';

const mapStateToProps = state => {
  const { loading, list, next } = state.list;
  return { loading, list, next }
};

const ListContainer = connect(
  mapStateToProps,
  {
    fetchList: listOperations.fetchList,
    removeListItem: listOperations.removeListItem,
    fetchNextListPage: listOperations.fetchNextListPage,
  }
)(ListComponent);

export default ListContainer;
