import Creators from './actions';
import { push } from 'react-router-redux';
import axios from 'axios';
import ReactGA from 'react-ga';

import api from '../../../apiService';

const signUpRequestAction = Creators.signUpRequest;
const signUpSuccessAction = Creators.signUpSuccess;
const signUpErrorAction = Creators.signUpError;
const signUpClearAction = Creators.signUpClear;
const signInRequestAction = Creators.signInRequest;
const signInSuccessAction = Creators.signInSuccess;
const signInErrorAction = Creators.signInError;
const signOutAction = Creators.signOut;

const signUp = (first_name, last_name, email, password, mobile, gender, age_group) => {
  return (dispatch) => {
    dispatch(signUpRequestAction());
    api.post('/api/v1/auth/users/create/', { first_name, last_name, email, password, mobile, gender, age_group })
      .then((response) => {
        if(response.status === 201) {
          ReactGA.event({
            category: 'User',
            action: 'Sign up',
          });
          dispatch(signUpSuccessAction());
          dispatch(push('/account/sign-up-confirm'));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          dispatch(signUpErrorAction(error.response.data));
        }
      });
  };
};

const signIn = (email, password, redirect='/') => {
  return (dispatch) => {
    dispatch(signInRequestAction());
    axios({method: 'post', url: '/api/v1/auth/login/', auth: { username: email, password: password }})
      .then((response) => {
        ReactGA.event({
          category: 'User',
          action: 'Sign in',
          label: 'Username & Password'
        });
        dispatch(signInSuccessAction(response.data.user));
        api.setToken(response.data.token);
        dispatch(push(redirect));
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          dispatch(signInErrorAction(error.response.data.detail));
        }
      });
  };
};

const getUserAndSignIn = () => {
  return (dispatch) => {
    api.get('/api/v1/auth/me/')
      .then((response) => {
        dispatch(signInSuccessAction(response.data.user));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const signOut = () => {
  return (dispatch) => {
    api.post('/api/v1/auth/logout/')
      .then((response) => {
        ReactGA.event({
          category: 'User',
          action: 'Sign out',
        });
        api.removeToken();
        dispatch(signOutAction());
        dispatch(push('/'));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

const googleSignIn = (code, redirect='/') => {
  return (dispatch) => {
    dispatch(signInRequestAction());
    api.post('/api/v1/login/social/knox_user/google-oauth2/', { code: code })
      .then((response) => {
        ReactGA.event({
          category: 'User',
          action: 'Sign in',
          label: 'Google'
        });
        dispatch(signInSuccessAction(response.data));
        api.setToken(response.data.token);
        dispatch(push(redirect));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export default { signUp, signUpClearAction, signIn, getUserAndSignIn, signOut, googleSignIn };
