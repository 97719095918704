import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import SignUpForm from './SignUpForm';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});


class SignUpComponent extends Component {

  componentWillUnmount() {
    this.props.signUpClear();
  }

  render() {
    const { signUp, signUpFirstNameError, signUpLastNameError, signUpEmailError, signUpPasswordError, signUpLoading } = this.props;
    return (
      <div>
        <SignUpForm
          onSubmit={signUp}
          firstNameError={signUpFirstNameError}
          lastNameError={signUpLastNameError}
          emailError={signUpEmailError}
          passwordError={signUpPasswordError}
          loading={signUpLoading}
        />
      </div>
    );
  }
}

SignUpComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignUpComponent);