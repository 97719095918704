import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import api from "../../../../apiService";
// import {push} from 'react-router-redux';
// import store from '../../../../Store';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 8,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    // marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit * 3,
  },
  submit: {
    // marginTop: theme.spacing.unit * 3,
  },
  field: {
    marginBottom: theme.spacing.unit * 3,
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  heading: {
    textAlign: 'center',
    fontWeight: '900',
    fontSize: '5rem',
    paddingTop: theme.spacing.unit * 8,
  },
  subHeading: {
    textAlign: 'center',
    fontWeight: '500',
    fontSize: '2rem',
    paddingBottom: theme.spacing.unit * 8,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  }
});


class ForgotPasswordComponent extends Component {
  state = {
    email: '',
    emailError: null,
    showSuccess: false,
    showFailure: false,
    loading: false,
  };

  handleSubmit(event) {
    event.preventDefault();
    this.clearErrors();
    this.setState({loading: true});

    if (this.validate()) {
      api.post('/api/v1/auth/password/reset/', { email: this.state.email  })
        .then((response) => {
          if(response.status === 204) {
            this.setState({showSuccess: true});
          } else {
            this.setState({showFailure: true});
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({showFailure: true});
        });
    }
    this.setState({loading: false});
  }

  clearErrors() {
    this.setState({
      emailError: null,
    });
  }

  validate() {
    let emailError = null;

    // Validate email
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(this.state.email)) {
      emailError = 'Please enter a valid email address';
    }

    if (emailError) {
      this.setState({
        emailError,
      });
      return false;
    }
    return true;
  }

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value, [`${prop}Error`]: null });
  };

   renderEmailError() {
    if (!!this.state.emailError) {
      return (
        <FormHelperText id="email-text">{this.state.emailError}</FormHelperText>
      );
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.showSuccess) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>Password reset email sent</Typography>
          <Typography className={classes.subHeading} color={'textSecondary'}>Please check your email for instructions.</Typography>
        </div>
      );
    }

    if (this.state.showFailure) {
      return (
        <div className={classes.root}>
          <Typography className={classes.heading} color={'textSecondary'}>There has been an error resetting your password</Typography>
          <Typography className={classes.subHeading} color={'textSecondary'}>Please contact us for help.</Typography>
        </div>
      );
    }

    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography variant="h4">Forgot Password</Typography>

          <form
            className={classes.form}
            onSubmit={(event) => this.handleSubmit(event)}
            noValidate
          >
            <FormControl
              className={classes.field}
              error={!!this.state.emailError}
              required={false}
              fullWidth
            >
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                value={this.state.email}
                onChange={this.handleChange('email')}
                inputProps={{
                  required: true,
                  type: 'email'
                }}
                autoComplete="email"
                autoFocus
              />
              {this.renderEmailError()}
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={this.state.loading}
            >
              { this.state.loading ? 'Submitting...' : 'Submit' }
            </Button>

          </form>
        </Paper>
      </main>
    );
  }
}

ForgotPasswordComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ForgotPasswordComponent);
