const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
const REQUEST_CATEGORY = 'REQUEST_CATEGORY';
const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY';
const CLEAR_DATA = 'CLEAR_DATA';
const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';

export default {
  REQUEST_PRODUCTS,
  RECEIVE_PRODUCTS,
  REQUEST_CATEGORY,
  RECEIVE_CATEGORY,
  CLEAR_DATA,
  CLEAR_PRODUCTS,
}