import types from './types';

const INITIAL_STATE = {
  loading: true,
  page: null
};

const pageReducer = (state=INITIAL_STATE, action) => {
  switch(action.type) {
    case types.REQUEST_PAGE:
      return INITIAL_STATE;

    case types.RECEIVE_PAGE:
      return {
        ...state,
        page: action.payload,
        loading: false
      };

    default:
      return state;
  }
};

export default pageReducer;
