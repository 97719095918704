import types from './types.js';

const requestProduct = () => {
  return({
    type: types.REQUEST_PRODUCT,
  });
};

const receiveProduct = (product) => {
  return({
    type: types.RECEIVE_PRODUCT,
    payload: product
  });
};

const requestStoreLocations = () => {
  return({
    type: types.REQUEST_STORE_LOCATIONS,
  });
};

const receiveStoreLocations = (locations) => {
  return({
    type: types.RECEIVE_STORE_LOCATIONS,
    payload: locations
  });
};

const clearData = () => {
  return({
    type: types.CLEAR_DATA,
  });
};

export default {
  requestProduct,
  receiveProduct,
  requestStoreLocations,
  receiveStoreLocations,
  clearData,
}
