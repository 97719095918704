import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import homeReducer from './app/home/duck';
import categoryReducer from './app/category/duck';
import primaryCategoryReducer from './app/primaryCategory/duck';
import coreReducer from './app/core/duck';
import productReducer from './app/product/duck';
import accountReducer from './app/account/duck';
import pageReducer from './app/page/duck';
import listReducer from './app/list/duck';


export default combineReducers({
  home: homeReducer,
  category: categoryReducer,
  primaryCategory: primaryCategoryReducer,
  core: coreReducer,
  product: productReducer,
  account: accountReducer,
  page: pageReducer,
  list: listReducer,
  routing: routerReducer,
});
