import { connect } from 'react-redux';
import CategoryComponent from './CategoryComponent';
import { categoryOperations } from './duck';
import Creators from './duck/actions';

const mapStateToProps = state => {
  const { products, count, next, previous, loading, category } = state.category;
  const { signedIn } = state.account;
  return { products, count, next, previous, loading, category, signedIn }
};

const CategoryContainer = connect(
  mapStateToProps,
  {
    clearData: Creators.clearData,
    clearProducts: Creators.clearProducts,
    fetchProducts: categoryOperations.fetchProducts,
    fetchCategory: categoryOperations.fetchCategory
  }
)(CategoryComponent);

export default CategoryContainer;
